// Article listing
//
.article-listing {

  // A listing marked as Featured
  .featured {
    background-color: $primary-lighter-color;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  h3 {
    margin-top: 0;
  }
}