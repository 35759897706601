// Gray carousel.

#homepage-gray-carousel {
  @include breakpoint($small-only) {
    div.slick-track {

    }
  }

  div.slick-track {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .slick-dots li,
    .slick-dots:after,
    .slick-dots:before {
      background: none;
    }

    @include breakpoint($small-only) {
      .item.slick-slide.slick-current.slick-active {
        height: auto;
      }
    }

    @include breakpoint($large-up) {
      .item.slick-slide.slick-current.slick-active {
        //height: 381px;
      }
    }
  }

  .slick-dots {
    bottom: 3px;
    &:before, &:after, li {
      background: none;
    }
  }

  .panel-body {
    padding: 25px;
    padding-bottom: 35px;
  }

  .slick-slider .slick-list .item-plain {
    padding: 0;
  }

  @include breakpoint($xsmall-only) {
    .slick-slider .slick-list .item-plain {
      padding: 0 10px;
    }

    .carousel .carousel-inner .slick-prev {
      left: 10px
    }
    .carousel .carousel-inner .slick-next {
      right: 10px
    }
  }

  @include breakpoint($small-only) {
    .slick-slider .slick-list .item-plain {
      padding: 0 10px;
    }

    .carousel .carousel-inner .slick-prev {
      left: 10px
    }
    .carousel .carousel-inner .slick-next {
      right: 10px
    }
  }

}
