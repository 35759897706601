// Layout
//

$layout-main-padding: 0 0 50px 0;

//** Default vertical space between stacked columns
$layout-stacked-gutter: 30px;

//** Block grids gutters
$block-grid-gutter-small:     4%;
$block-grid-gutter-medium:    3%;
$block-grid-gutter-large:     2.5%;

//** Block grids vertical space
$block-grid-vertical-space:   20px;