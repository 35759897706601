// Buttons
//

//** Filter search results page
.btn-spaced {
  padding-left: 20%;
  padding-right: 20%;
  margin: 15px 0 0 15px;
}

//** Disabled default button border
.btn {
  border: 0;
}

//** Overrides Bootstraps button-variant
//   controls the colour, bg-colour, :hover bg-colour
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-hover-bg);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-hover-bg);
}
.btn-cancel {
  @include button-variant($btn-cancel-color, $btn-cancel-bg, $btn-cancel-hover-bg);
}
.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-hover-bg);
}
.btn-tertiary {
  @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-hover-bg);
}

//** Overrides Bootstraps button-size
//   controls padding, font-size, line-height & border radius
.btn {
  @include button-size($padding-base-vertical, $padding-base-horizontal, $btn-base-font-size, $line-height-base, $btn-border-radius-base);
}
.btn-lg {
  @include button-size($padding-large-vertical, $padding-large-horizontal, $btn-lg-font-size, $line-height-large, $btn-border-radius-large);
}
.btn-sm {
  @include button-size($padding-small-vertical, $padding-small-horizontal, $btn-sm-font-size, $line-height-small, $btn-border-radius-small);
}
.btn-xs {
  @include button-size($padding-small-vertical, $padding-small-horizontal, $btn-xs-font-size, $line-height-small, $btn-border-radius-small);
}

//** Custom button with icon
.btn-with-icon {
  border-collapse: separate;
  display: table;
  padding: 0;
  position: relative;
  text-align: left;
  white-space: normal; // Overrides Bootstrap default no-wrap rule

  //** Positions the icon to the end of the button
  &.btn-block {
    .btn-text {
      width: 100%;
    }
  }

  // Button text
  .btn-text {
    display: table-cell;
    vertical-align: middle;
  }

  // Button text padding
  &.btn .btn-text {
    padding: $padding-base-vertical $padding-base-horizontal;
  }
  &.btn-lg .btn-text {
    padding: $padding-large-vertical $padding-large-horizontal;
  }
  &.btn-sm .btn-text {
    padding: $padding-small-vertical $padding-small-horizontal;
  }

  // Button icon addon
  .btn-icon {
    color: white;
    display: table-cell;
    vertical-align: middle;
  }

  // Button icon size
  &.btn .btn-icon {
    font-size: floor(($font-size-base * 2.286)); // 32px 32/14
  }
  &.btn-lg .btn-icon {
    font-size: ($font-size-base * 3); // 42px 42/14
  }
  &.btn-sm .btn-icon {
    font-size: ceil(($font-size-base * 1.571)); // 22px 22/14
  }
  &.btn .fa-search {
    font-size: ceil($font-size-base/0.6); // 28px
  }

  // Button icon padding & border radius
  &.btn .btn-icon {
    @include button-icon-padding(0, ($padding-base-horizontal+2), $border-radius-base, $border-radius-base);
  }
  &.btn-lg .btn-icon {
    @include button-icon-padding(0, ($padding-large-horizontal+4), $border-radius-large, $border-radius-large);
  }
  &.btn-sm .btn-icon {
    @include button-icon-padding(0, $padding-small-horizontal, $border-radius-small, $border-radius-small);
  }

  //** Button icon colour & bg colour
  &.btn-default .btn-icon {
    @include button-icon-variant($btn-default-icon-color, $btn-default-icon-bg);
  }
  &.btn-primary .btn-icon {
    @include button-icon-variant($btn-icon-color, $btn-primary-icon-bg);
  }
  &.btn-secondary .btn-icon {
    @include button-icon-variant($btn-icon-color, $btn-secondary-icon-bg);
  }
  &.btn-tertiary .btn-icon {
    @include button-icon-variant($btn-icon-color, $btn-tertiary-icon-bg);
  }
}

//** Button Link with icon
.btn-link {

  // Removes the default text underline on button links
  &:hover {
    text-decoration: none;
  }

  .btn-icon {
    color: $btn-link-icon-color;
  }

  // Button icon size
  &.btn .btn-icon {
    font-size: $font-size-large; // 18px
    padding-left: 10px; // Overrides the default button icon padding
    line-height: 0.4;
  }
  &.btn-lg .btn-icon {
    font-size: ceil(($font-size-base * 1.57)); // 22px 22/14
    line-height: 0.8;
  }
  &.btn-sm .btn-icon {
    font-size: $font-size-base; // 14px
  }

  // Override large link button font size
  &.btn-lg {
    font-size: ceil(($font-size-base * 1.428)); // 20px 20/14
  }
}

// overriding bootstraps button block padding as it cannot be done within the bootstrap variables file.
.btn-block + .btn-block {
  margin-top: 15px;
}
