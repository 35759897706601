// Tables
//
// Used to customise the table element and add controllable settings to
// our custom TableSaw theme (see /vendor/filament-tablesaw/).

//** Default background color used for all tables.
//$table-bg:                      white;

//** Padding for `<th>`s and `<td>`s.
//$table-cell-padding:            8px;

//** Padding for cells in `.table-condensed`.
//$table-condensed-cell-padding:  5px;

//** Text muted color
//$text-muted:                    $gray;

//** Table heading color
$table-heading:                 $brand-primary !default;

//** Table heading background color
$table-heading-bg:              $primary-light-color !default;

//** Thickness of border under thead
//$table-heading-border-width:    1px;

//** Border color for table and cell borders.
//$table-border-color:            $gray;