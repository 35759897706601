// Social brand icons
//

.list-share {
  text-align: right;
  font-size: $font-size-medium;
  margin-bottom: 0;

  @include breakpoint($medium-up) {
    text-align: right;
  }

  li {
    padding-right: 2px;

    a {
      background: $gray;
      color: #fff;
      display: inline-block;
      padding: 8px;
      text-align: center;
      width: 34px;
    }

    // Social icons bg colours
    &.facebook a {
      background-color: $icon-facebook-bg;
    }
    &.twitter a {
      background-color: $icon-twitter-bg;
    }
    &.linkedin a {
      background-color: $icon-linkedin-bg;
    }

    // Social icons bg colour on :hover
    & a:hover {
      background: $gray-dark;
    }
  }
}
