// Listing heading
//

.listing-heading {

  .listing-title {

    @include breakpoint($small-up) {
      display: inline;
      margin-right: 20px;
    }

    span {
      font-weight: bold;
    }
  }

  // List utils includes RSS, Email and Save services
  .list-utils {
    display: inline-block;
    margin-bottom: 0;

    li {
      margin-right: 10px;
      margin-bottom: 15px;
      @include breakpoint($small-up) {
        margin-bottom: 0;
      }

      a {
        color: $gray-light;

        i {
          font-size: ceil(($font-size-base * 1.571)); // 22px 22/14
          vertical-align: middle;

          @include breakpoint($small-up) {
            vertical-align: inherit;
          }
        }
      }
    }
  }
}

