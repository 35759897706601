// Masonry grids
//

//** Used on narrower screen sizes (generally small and below)
$masonry-default-gutter:        5% !default;

//** Used on wider screen sizes (generally medium up)
$masonry-small-gutter:          3% !default;

//** Vertical gap between rows
$masonry-row-gap:               30px !default;

//** Determine grid item widths taking into account gutters used
$masonry-2wide-width:           (100% / 2) - ($masonry-default-gutter / 2) !default;
$masonry-3wide-width:           (100% / 3) - (($masonry-small-gutter * 2) / 3) !default;
$masonry-4wide-width:           (100% / 4) - (($masonry-small-gutter * 2) / 4) !default; //DR

$masonry-2wide-split-gutter:    $masonry-default-gutter / 2 !default;
$masonry-3wide-split-gutter:    $masonry-small-gutter / 2 !default;
$masonry-4wide-split-gutter:    $masonry-small-gutter / 2 !default; //DR

// Variable items heights
//
//** List of short heights
//$masonry-short-scale:           (200px, 230px, 250px) !default;
$masonry-short-scale:           (200px, 230px, 250px, 200px) !default; //DR

//** List of tall heights
//$masonry-tall-scale:            (280px, 340px, 380px) !default;
$masonry-tall-scale:            (280px, 340px, 380px, 450px) !default; //DR

//** For older legacy browsers not supporting nth-child-of() selector
//$masonry-default-height:        masonry-short-height(3) !default;
$masonry-default-height:        masonry-short-height(4) !default; //DR


$masonry-font-size:             $block-title-font-size !default;
$masonry-span-font-size:        $block-title-span-font-size !default;
$masonry-font-size-small:             $block-title-font-size-small !default;
$masonry-span-font-size-small:        $block-title-span-font-size-small !default;
$masonry-bg-img:                      '../images/masonry/masonry-480x727.jpg' !default; //DR