.panel-hover {
  text-align: center;
  float: left;
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.panel-hover,
.item-hover,
.item-hover .mask,
.item-img,
.item-info {
  width: 100%;
  height: 230px;

  @include breakpoint($small-up) {
    height: 350px;
  }
  @include breakpoint($medium-up) {
    height: 600px;
  }

}

.row-1-1 {
  .panel-hover,
  .item-hover,
  .item-hover .mask,
  .item-img,
  .item-info {
    width: 100%;
    height: 230px;

    @include breakpoint($xsmall-up) {
      height: 170px;
    }
    @include breakpoint($small-up) {
      height: 200px;
    }
    @include breakpoint($medium-up) {
      height: 300px;
    }
    @include breakpoint($large-up) {
      height: 350px;
    }

  }
  .item-type-line .item-info {
    @include breakpoint($xsmall-only) {
      font-size: 0.6em;
      .headline {
        font-size: 1.3em;
      }
    }
  }
  .item-type-line .item-info {
    @include breakpoint($small-only) {
      font-size: 0.8em;
      .headline {
        font-size: 1.6em;
      }
    }
  }
}

.row-1-1-1 {
  .panel-hover,
  .item-hover,
  .item-hover .mask,
  .item-img,
  .item-info {
    width: 100%;
    height: 190px;

    @include breakpoint($xsmall-up) {
      height: 190px;
    }
    @include breakpoint($small-up) {
      height: 140px;
    }
    @include breakpoint($medium-up) {
      height: 190px;
    }
    @include breakpoint($large-up) {
      height: 220px;
    }

  }
  .item-type-line .item-info {
    @include breakpoint($small-only) {
      font-size: 0.6em;
      .headline {
        font-size: 1.3em;
      }
    }
    @include breakpoint($medium-only) {
      font-size: 0.8em;
      .headline {
        font-size: 1.6em;
      }
    }
  }
}

.row-1-1-1-1 {
  .panel-hover,
  .item-hover,
  .item-hover .mask,
  .item-img,
  .item-info {
    width: 100%;
    height: 190px;

    @include breakpoint($xsmall-up) {
      height: 190px;
    }
    @include breakpoint($small-up) {
      height: 400px;
    }
    @include breakpoint($medium-up) {
      height: 135px;
    }
    @include breakpoint($large-up) {
      height: 170px;
    }

  }
  .item-type-line .item-info {
    @include breakpoint($medium-only) {
      font-size: 0.6em;
      .headline {
        font-size: 1.3em;
      }
    }
    @include breakpoint($large-up) {
      font-size: 0.8em;
      .headline {
        font-size: 1.6em;
      }
    }
  }
}

.item-hover,
.item-hover .mask,
.item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.item-type-line {
  .item-hover {
    z-index: 100;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    opacity: 0;
    cursor: pointer;
    //display: block;
    text-decoration: none;
    text-align: center;
    padding: 0 15px;
    display: table;
  }
  .item-info {
    //z-index: 10;
    color: #ffffff;
    display: table-cell;
    vertical-align: middle;
    //width:500px;
    position: relative;
    z-index: 5;
    .headline {
      font-size: floor($font-size-h3);
    }
    .line {
      height: 1px;
      width: 0%;
      margin: 10px auto;
      background-color: #ffffff;
      -webkit-transition: all 500ms ease-out;
      -moz-transition: all 500ms ease-out;
      -o-transition: all 500ms ease-out;
      transition: all 500ms ease-out;

    }
    .info {
      display: block;
      padding: 0 0 5px 0;
    }
    a {
      //padding:10px 0 0 0;
      color: #fff;
      text-decoration: underline;
    }
  }
}

.item-type-line .item-hover .mask {
  background-color: $primary-base-color;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 0;
}

.item-type-line .item-hover:hover .line {
  width: 40%;
}

.item-type-line .item-hover:hover {
  opacity: 1;
  cursor: default;
}

.item-img {
  background-color: $primary-base-color;
  z-index: 0;
  img {
    width: 100%;
  }
}

