//// Off canvas
////
//
////** Off-canvas z-index's
//$zindex-offcanvas-background:     1000;
//$zindex-offcanvas-panel:          1010;
//
////** Default width of parent off-canvas panel will occupy
//$offcanvas-content-width:         85%;
//
////** Padding applied to the off canvas body
//$offcanvas-header-padding:        15px;
//
////** Padding applied to the off canvas body
//$offcanvas-body-padding:          0;
//
////** Header border color
//$offcanvas-header-border-color:   $gray-lighter;
//
////** Background color of off canvas content area
//$offcanvas-content-bg:            #127eb9;
//
////** Backdrop background color
//$offcanvas-backdrop-bg:           #000;
//
////** Backdrop opacity
//$offcanvas-backdrop-opacity:      .4;