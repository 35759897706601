// Header information
//
$header-info-strapline-color:                 $primary-base-color !default;
$header-info-strapline-color-sm:              $primary-base-color !default;
$header-info-phone-color:                     $primary-base-color !default;
$header-info-email-color:                     $primary-base-color !default;
$header-info-icon-color:                      $gray-light !default;

$header-info-strapline-font-size-sm:          $font-size-base !default;
$header-info-strapline-font-size-lg:          floor($font-size-base * 1.286) !default; // 18px

$header-info-phone-font-size-sm:              floor($font-size-base * 1.429) !default; // 20px
$header-info-phone-font-size-lg:              ceil($font-size-base * 1.857) !default; // 26px

$header-info-email-font-size-sm:              $font-size-base !default; // 14px
$header-info-email-font-size-lg:              floor($font-size-base * 1.286) !default; // 18px