// Images
//

img {
  vertical-align: middle;
}

// Simple bordered images
.img-bordered {
  border: 1px solid $img-border-color;
}

// Scaffold image
//
// The scaffold image is a transparent placeholder which creates a width and height
// so responsive background images of different ratios may be used.
.img-scaffold {
  display: block;
  max-width: 100%;
}
