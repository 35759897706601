$lato-path:     "../fonts/lato/";

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: url(#{$lato-path}lato-black-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-black-webfont.woff) format('woff'),
  url(#{$lato-path}lato-black-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-black-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  src: url(#{$lato-path}lato-blackitalic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-blackitalic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-blackitalic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-blackitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(#{$lato-path}lato-bold-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-bold-webfont.woff) format('woff'),
  url(#{$lato-path}lato-bold-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-bold-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(#{$lato-path}lato-bolditalic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-bolditalic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-bolditalic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-bolditalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url(#{$lato-path}lato-hairline-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-hairline-webfont.woff) format('woff'),
  url(#{$lato-path}lato-hairline-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-hairline-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url(#{$lato-path}lato-hairlineitalic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-hairlineitalic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-hairlineitalic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-hairlineitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url(#{$lato-path}lato-italic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-italic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-italic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-italic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url(#{$lato-path}lato-italic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-italic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-italic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-italic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: url(#{$lato-path}lato-light-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-light-webfont.woff) format('woff'),
  url(#{$lato-path}lato-light-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-light-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 200;
  src: url(#{$lato-path}lato-lightitalic-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-lightitalic-webfont.woff) format('woff'),
  url(#{$lato-path}lato-lightitalic-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-lightitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(#{$lato-path}lato-regular-webfont.eot);
  src: local('☺'),
  url(#{$lato-path}lato-regular-webfont.woff) format('woff'),
  url(#{$lato-path}lato-regular-webfont.woff2) format('woff2'),
  url(#{$lato-path}lato-regular-webfont.svg) format('svg');
}
