// Navs
//

//** Nav link hover bg colour
$nav-link-hover-bg:                               $gray-xlight;

//** Shared nav styles
$nav-link-padding:                                5px 10px;


// Tabs
// =========
//** Colours
$nav-tabs-bg:                                     white;
$nav-tabs-link-hover-bg:                          $gray-xlight;
$nav-tabs-link-active-bg:                         $gray-lighter;
$nav-tabs-content-bg:                             transparent;

//** Text
$nav-tabs-color:                                  $gray;
$nav-tabs-hover-color:                            $gray;
$nav-tabs-active-hover-color:                     $gray-dark;

//** Borders
$nav-tabs-border-color:                           $gray;

// Structural
// =========
//** Default vertical between tabs and tab content (0px for no suspension)
$nav-tabs-suspend-height:                         0px;

//** Default border options
$nav-tabs-border-size:                            1px;
$nav-tabs-content-border-size:                    1px;

//** Default gutter space between tabs
$nav-tabs-gutter:                                 0px;