// Article heading
//
.article-heading{
  h1{
    margin-top:0;
  }
}
.meta-author,
.meta-category {
  margin: 0;
}