// Media Listing layouts

// Landscape layout
@mixin layout-landscape {

  .col-media {
    @include grid-span(6, 1, $gutter: 20px);
    //line-height: 0;

    @include breakpoint($medium-up, $no-query: true) {
      @include grid-span(3, 1);
    }
  }

  .col-content {
    @include grid-span(6, 7, $gutter: 20px);

    @include breakpoint($medium-up, $no-query: true) {
      @include grid-span(9, 4);
    }
  }
}

// Portrait layout
@mixin layout-stacked {

  .col-media {
    @include grid-span(12, 1, $gutter: 20px);
    //line-height: 0;
    margin-bottom: 20px;
  }

  .col-content {
    @include grid-span(12, 1, $gutter: 20px);
  }
}