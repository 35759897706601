// Breadcrumbs
//

.breadcrumb {
  margin-bottom: 0; // Override default Bootstrap margin
  padding: 8px 5px;
  text-transform: capitalize;

  > li {

    a {
      color: $breadcrumb-color;
    }

    + li:before {
      font-family: $icon-font-family;
      font-size: $font-size-small;
    }
  }
}
