// Colours
//

//** Networkers palette
//$primary-dark-color:          #006394; // *networkers
//$primary-base-color:          #0076b0; // *networkers
//$primary-light-color:         #c8dde7; // *networkers
//$primary-lighter-color:       #e6eff4; // *networkers
//
//$secondary-dark-color:        #e59500; // *networkers
//$secondary-base-color:        #f6a000; // *networkers
//
//$accent-base-color:           #ed1c24; // *networkers
