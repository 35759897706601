// Footer legal
//

.footer-legal {
  font-size: $font-size-base;
  p {
    color: $base-font-color;
  }

  // Legal nav
  .footer-legal-nav {

    li {
      padding: 0;

      &:after {
        content: "|";
        font-weight: $font-weight-light;
      }

      &:last-child:after {
        content: "";
      }

      // Nav link styles
      a {
        color: $base-font-color;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

  }

}
