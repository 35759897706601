// Re-usable standard grid spans
// 1 columns
//
.row-1 {
  /*
  1 column row.
  -----------------------------
  |                           |
  |           Col 1           |
  |                           |
  -----------------------------
  */
  > .col-1 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px
  }
  @extend %row-stack-spacing-small-up;
}
