 // Featured Projects
//

.block-grid {

  li {
    position: relative;

    // Block title overlay with rounded bg
    .block-title-overlay {
      background-color: #000; // IE8 fallback
      background-color: $block-title-overlay-bg;
      border-top-right-radius: $block-title-overlay-radius;
      border-bottom-right-radius: $block-title-overlay-radius;
      color: $block-title-font-colour;
      font-size: $block-title-font-size;
      padding: $block-title-overlay-padding;
      position: absolute;
      bottom: 5%;
      left: 0;
      max-width: 80%;
    }
  }
}

