// Footer nav
//

.footer-navigation {

  // Trigger element for collapse
  h4 {
    font-weight: $headings-font-weight;
    padding-bottom: 10px;
    color:$primary-dark-color;
    border-bottom:1px solid $footer-nav-heading-border-color;
    a{
      color:$footer-nav-headlink-color;
    }
    & a:hover{
      text-decoration:none;
    }
  }

  // Nested navigation lists
  ul {
    list-style: none;
    margin: 0;
    padding:0;

    li {
      font-size:$footer-nav-font-size;
      padding: 4px 0;
      //font-weight: $font-weight-light;

      a {
        text-decoration: none;
        color: $base-font-color;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  // Override UX behaviour when collapse behaviour is disabled
  // for medium up breakpoints (applied using enquire.js)
  @include breakpoint($medium-up, $no-query: true) {

    // Prevent cursor being a pointer on collapse trigger element
    [data-toggle="collapse"] {
      cursor: default;
    }

    // Ensure collapsible content is always shown expanded
    .collapse {
      display: block;
    }

    // Forcibly hide the icons
    .collapse-icon {
      &:after {
        display: none;
      }
    }
  }
}

// Shame list...
.lt-ie9 { // For IE 8 never show icons
  .footer-navigation {
    .collapse-icon {
      &:after {
        display: none;
      }
    }
  }
}
