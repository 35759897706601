// Block grid
// Block grids provide a way for evenly splitting content that can span over multiple rows
//
.block-grid {

  @extend %clearfix;

  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    float: left;
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: $block-grid-vertical-space;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//
// Define block grid presets
//
// block-grid-<column>-<breakpoint>

// 2 column grid at *all* breakpoints
.block-grid-2 {
  @include block-grid(2, $block-grid-gutter-medium)
}

// 3 column grid at *all* breakpoints
.block-grid-3 {
  @include block-grid(3, $block-grid-gutter-medium)
}

// 4 column grid at *all* breakpoints
.block-grid-4 {
  @include block-grid(4, $block-grid-gutter-medium)
}

// 2 column at xsmall
.block-grid-2-xsmall {
  @include breakpoint($xsmall-only) {
    @include block-grid(2, $block-grid-gutter-small)
  }
}
// 2 column at small only
.block-grid-2-small {
  @include breakpoint($small-only) {
    @include block-grid(2, $block-grid-gutter-small)
  }
}
// 2 column for medium only
.block-grid-2-medium {
  @include breakpoint($medium-only) {
    @include block-grid(2, $block-grid-gutter-medium)
  }
}
// 2 column for large only
.block-grid-2-large {
  @include breakpoint($large-only, $no-query: true) {
    @include block-grid(2, $block-grid-gutter-medium)
  }
}

// 2 column at xsmall
.block-grid-3-xsmall {
  @include breakpoint($xsmall-only) {
    @include block-grid(3, $block-grid-gutter-small)
  }
}
// 2 column at small only
.block-grid-3-small {
  @include breakpoint($small-only) {
    @include block-grid(3, $block-grid-gutter-small)
  }
}
// 2 column for medium only
.block-grid-3-medium {
  @include breakpoint($medium-only) {
    @include block-grid(3, $block-grid-gutter-medium)
  }
}
// 2 column for large only
.block-grid-3-large {
  @include breakpoint($large-only, $no-query: true) {
    @include block-grid(3, $block-grid-gutter-medium)
  }
}

// 4 column for medium only
.block-grid-4-medium {
  @include breakpoint($medium-only, $no-query: true) {
    @include block-grid(4, $block-grid-gutter-medium)
  }
}

// 3 column for large up
.block-grid-3-medium-up {
  @include breakpoint($medium-up, $no-query: true) {
    @include block-grid(3, $block-grid-gutter-medium)
  }
}
// 3 column for large up
.block-grid-3-large-up {
  @include breakpoint($large-up, $no-query: true) {
    @include block-grid(3, $block-grid-gutter-medium)
  }
}
// 3 column for xlarge up
.block-grid-3-xlarge-up {
  @include breakpoint($xlarge-up, $no-query: true) {
    @include block-grid(3, $block-grid-gutter-medium)
  }
}

// 4 column for medium up
.block-grid-4-medium-up {
  @include breakpoint($medium-up, $no-query: true) {
    @include block-grid(4, $block-grid-gutter-medium)
  }
}
// 4 column for large up
.block-grid-4-large-up {
  @include breakpoint($large-up) {
    @include block-grid(4, $block-grid-gutter-large)
  }
}
