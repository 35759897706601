// Default Panel - Overwriting bootstrap defaults to suit overall panel design. This is the base panel.
//
.panel {
  box-shadow: none;

  .panel-head {
    @extend %heading-margin-reset;
  }

  .panel-group {

    .panel-heading {

      + .panel-collapse > .panel-body,
      + .panel-collapse > .list-group {
        border-top: 0;
      }
    }
  }

  // Panel background colour themes
  &.panel-bg-default {
    background-color: $gray-xlight;
  }
  &.panel-bg-primary {
    background-color: $primary-lighter-color;
  }

  // List-group with icons
  .list-group-icons {
    font-size: $font-size-medium;

    .list-group-item {
      background-color: #fff;
      border-color: $primary-light-color;
    }

    .status-icon {

      &:after {
        display: inline-block;
        font-family: $icon-font-family;
        font-size: $font-size-large;
        float: right;
      }

      &.status-complete:after {
        color: $brand-success;
        content: $fa-var-check;
      }
      &.status-incomplete:after {
        color: $brand-danger;
        content: $fa-var-times;
      }
    }
  }

  .small-author{
    font-size:$panel-listing-butt-font-size;
  }

  // Buttons in panels
  .btn {
    font-size: ceil(($font-size-base * 1.2)); // ~34px;
    &.btn-block {
      border-radius: 0; // Removes default border radius
    }

    .btn-text {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .btn-icon {
      //font-size: ceil(($font-size-base * 2.42)); // ~34px;
      padding-left: 18px;
      padding-right: 18px;
    }
  }
}