// Alternative nav
//
#nav-primary.client {

  .logo {
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    .img-responsive {
      width: 170px;
      @include breakpoint($medium-up) {
        width: 200px;
      }
    }
  }

  &.stuck .logo {
    .img-responsive {
      width: 170px;

      @include breakpoint($medium-up) {
        width: 200px;
      }
    }
  }

  .navbar-collapse {
    padding: 0;

    &.collapse {
      display: none !important;
      float: left;

      @include breakpoint($large-up) {
        display: inline-block !important;
      }
    }
  }
  .hotdog-nav {
    display: inline-block;
    float: left;

    .fa {
      color: #fff;
    }

    @include breakpoint($large-up) {
      display: none;
    }
  }

  .navbar-collapse {

    float: none;
    width: 100%;
    margin: 0 0 20px 0;
    .nav {
      float: none;
      width: 100%;
      clear: both;
      margin: 0;

      > li {
        width: 100%;
        clear: both;
        > a {
          width: 100%;
          max-width: none;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      .dropdown-menu {
        margin-left: -5px;
      }
    }
    &.collapse{
      float:right;
    }

    @include breakpoint($large-up) {

      float: right;
      width: auto;
      margin: 0;
      .nav {
        float: right;
        width: auto;
        clear: none;
        > li {
          width: auto;
          clear: none;
          > a {
            width: auto;
          }
        }
      }

    }

    @include breakpoint($small-up) {
      .nav {
        .dropdown-menu {
          margin-left: 0;
        }
      }
    }

    &.collapse.in, &.collapse.collapsing {
      display: block !important;
    }

  }

  .hotdog-nav {
    float: left;
    padding: 40px 5px 40px 0;
    @include breakpoint($large-up) {
      display: none;
    }
  }

  .nav {
    > li {
      > a {
        //color: $navigation-alt-color;
        color: #fff;
      }
    }
  }

  .sub-area {
    background: #00507f;
    .nav {
      > li {
        > a {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
          @include breakpoint($large-up) {
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }
      }

      .dropdown-toggle {
        .caret {
          top: 49px;
        }
      }

    }
  }

}

