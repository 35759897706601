// Forms errors
// --------------------------------------------------

// Default form error styling
.has-error {
  @include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);

  // Error messaging text
  //.error-message {
  //  @include font-size(12);
  //}
}

.error-message {
  @include font-size(12);
  color: $state-danger-text;
  display: block;
}