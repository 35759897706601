// Search form
//
.search-form {
  color: #fff;
  font-size: $font-size-medium;
  @include breakpoint($medium-up) {
    font-size: $font-size-large;
  }
  padding: 15px 0;
  text-align: center;

  -webkit-backface-visibility: hidden;
  @include transition(font-size .9s cubic-bezier(0.23, 1, 0.32, 1));

  // make adjustments to the form after the focused class has been added
  &.focused {

    @include breakpoint($large-up) {
      font-size: ceil(($font-size-base * 1.9)); // 25px

      input.form-control {
        font-size: inherit;
        height: auto;
      }

      .control-label {
        @include breakpoint($medium-up) {

          -webkit-backface-visibility: hidden;
          @include transition(font-size .9s cubic-bezier(0.23, 1, 0.32, 1));
          font-size: ceil(($font-size-base * 2.7));
        }
      }

      .auto-wrapper {
        .dropdown {
          top: 43px;
        }
      }

      .btn-with-icon.btn.btn-search {
        font-size: ceil(($font-size-base * 1.8)); // 20px
        .fa-search {
          font-size: ceil(($font-size-base * 2.8)); // 40px
        }
      }
    }
  }

  input.form-control {
    border: none; // Disabled TWBS border
    box-shadow: none; // Disable TWBS box-shadow
  }

  .input-group-addon {
    background-color: #fff;
    border: none; // Disabled TWBS border
    box-shadow: none; // Disable TWBS box-shadow
    color: $gray-light;
    font-size: $font-size-xlarge;
  }

  // hide the narrative form 'labels' on smaller screens
  span.search-label {
    display: none;

    @include breakpoint($medium-up) {
      display: inline;
    }
  }

  a {
    font-size: $font-size-medium;
    color: $search-form-text-color;
    text-decoration: underline;
    margin: 15px 0 0 0;
    display: block;
    font-weight: $search-form-font-weight;
    &:hover, &:focus {
      text-decoration: none;
      color: $search-form-text-color;
      outline: none;
    }

    text-align: right;
    @include breakpoint($medium-up) {
      clear: left;
    }
  }

  // Used to allow central alignment of form with other elements aligned relative to the content
  .wrap {
    padding: 0 15px;
    @include breakpoint($small-up) {
      display: inline-block;
    }
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }

  .input-group {
    width: 100%;
    @include breakpoint($small-up) {
      width: 290px;
    }
  }

  .auto-wrapper {
    display: inline;
    position: relative;

    .input-group {
      width: 100%;
      @include breakpoint($small-up) {
        width: 285px;
      }
    }
    .form-control {
      width: 100%;
      @include breakpoint($small-up) {
        width: 285px;
      }
    }
    .form-select {
      width: 100%;
      @include breakpoint($small-up) {
        width: 285px;
      }
    }

    .dropdown {
      position: absolute;
      left: 0;
      top: 43px;
      z-index: 100;
      @include box-shadow(0 6px 6px rgba(0, 0, 0, .175));

      @include breakpoint($medium-up) {
        left: 0;
      }

      background: #fff;
      color: $gray;
      min-width: 100%;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      display: none;

      // Autocomplete drop-down list
      > .dropdown-menu {
        //box-shadow: 3px 3px 3px $gray-dark;
        color: $gray;
        min-width: 100%;
        text-align: left;
        font-size: $font-size-medium;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: none;

        > li {

          > a {
            display: block;
            color: $text-color;
            margin: 0;
            padding: 10px 15px;
            text-decoration: none;
            text-align: left;
            white-space: normal;

            &:hover, &:focus, &:active {
              background-color: $gray-xlighter;
            }
          }

        }
        // overwrite bootstraps default autocomplete background colouring
        > .active {
          > a {
            background-color: $gray-xlighter;
          }
        }

      }

    }
  }

  .control-label {
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
      color: $search-form-text-color;
      font-size: ceil(($font-size-base * 1.976)); // 25px
      font-weight: $search-form-font-weight;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  // Default button overrides for search form
  .btn-with-icon.btn.btn-search {
    // full width button on mobile
    width: 100%;
    display: inline-table;

    @include breakpoint($small-up) {
      width: auto;
      margin-left: 10px;
      margin-bottom: 0;
    }
    // Float the text left to align properly with button icon
    .btn-text {
      float: left;
      font-size: $font-size-large*1.1;
      padding: 10px 15px;
      @include breakpoint($small-up) {
        display: none;
      }
    }
    // Float icon right on mobile due to other properties when making things full width
    .btn-icon {
      float: right;
      padding-top: 6px;
      padding-bottom: 6px;
      @include breakpoint($small-up) {
        float: none;
        border-radius: 4px;
        background: none;
      }
    }

    .fa-search {
      font-size: ceil(($font-size-base * 2.2));
      padding-top: 7px;
      padding-bottom: 11px;
    }
  }

  #search-location {
    @include breakpoint($small-only) {
      margin-left: 11px;
    }
  }

}

// overwrites for the google location module chosen for the back end
.pac-container {
  font-family: $font-family-base;
  .pac-item {
    color: $text-color;
    font-size: $font-size-small;
    .pac-item-query {
      font-size: $font-size-medium;
      padding-right: 8px;
    }
  }
}
.pac-logo {
  &:after {
    padding:8px;
    background-position: 95%;
    height:30px;
  }
}

// shame shame
.lt-ie9 .search-form {

  .form-inline .form-group, input.form-control {
    zoom: 1;
    display: inline;
  }
  .form-group {
    padding-bottom: 10px;
  }
  .btn-secondary {
    margin-top: 20px;
  }
  a {
    text-align: center;
  }
  .wrap {
    zoom: 1;
    display: inline;
  }
}
