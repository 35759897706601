// Accordion panel
//
.panel-accordion {
  background: $panel-accordion-bg;
  border: 0;

  .panel-heading {
    background: $panel-accordion-bg-head;
    h3{
      margin-bottom:0;
    }
  }

  .panel-group {
    margin-bottom: 0;
    padding: 5px 0;

    .panel {
      margin-top: 0;

      .collapse-icon-angle:after {
        font-size: $font-size-xlarge;
        margin-top: -5px;
      }

      .panel-heading {
        background:none;
        position:relative;
        p {
          margin: 0;
          color:$link-color;
          font-weight: $font-weight-light;
        }
        a.accordion-trigger{
          display:inline-block;
          position:absolute;
          top:0;
          right:0;
          padding:10px 15px;
          &:hover{
            p{color:#000;}
          }
        }
      }

      .panel-body {
        padding-top: 0;
        padding-bottom: 0;

        ul {
          list-style: none;

          li {

            a {
              padding-bottom: 5px;
              display: block;
            }
          }
        }
      }

    }
  }

  a.panel-heading{
    display:block;
  }

}