// Sizes
//
// These custom size classes can be applied to images and video media in CMS

@include breakpoint($small-up, $no-query: true) {

  // 1/4 width
  .width-25 {
    width: 25%;
  }

  // 1/3 width
  .width-33 {
    width: 33%;
  }

  // 1/2 width
  .width-50 {
    width: 50%;
  }

  // 2/3 width
  .width-66 {
    width: 66%;
  }

  // 3/4 width
  .width-75 {
    width: 75%;
  }
}

iframe.home-share {
  @include breakpoint($medium-up) {
    height: 155px;
  }
  @include breakpoint($large-up) {
    height: 197px;
  }
}

