// Utility icons
//

// Save, Email & SMS services
.list-utils {
  //font-size: $font-size-small;

  li {

    a {
      color: $gray-light;

      &:hover {
        color: darken($brand-primary, 10%);
        text-decoration: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }
}