//// Listing
////
//
////** Shortlist icon colour
//$icon-shortlist-color:            $gray-light !default;
//
////** Shortlist selected icon colour
$icon-shortlist-selected-color:   #f6a000;
//
////-- Promoted listing
////** Heading bg
$promo-listing-header-bg:         #c7d0e1;
////** Content bg
//$promo-listing-content-bg:        $primary-lighter-color !default;
//$promo-listing-label:             ceil(($font-size-base * 1)) !default;
//$promo-listing-label-top:         -3px !default;
//
////-- Standard listing
////** Heading bg
//$listing-header-bg:               $gray-xlight !default;
//$listing-header-label-padding:    .4em .6em .25em .6em !default;
//$font-size-listing-heading:       22px !default;
$promo-listing-header-color:         #28b4af;
//
////** Add to shortlist icon button
//$add-to-shortlist-bg:             $gray-lighter !default;
//$add-to-shortlist-color:          $gray !default;
//$add-to-shortlist-padding:        16px !default;

//** Label colours
//$label-promoted-bg:             $gray-dark !default;
//$label-justadded-bg:          $secondary-base-color !default;
//$label-featured-bg:        $primary-dark-color !default;