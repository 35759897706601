.row-1-1-1-1-1-1 {

  // Custom layout based on 6 column grid
  //  6 Stacked rows for extra small screens up.
  //  3 columns stacked above 3 for medium screens up.
  //  -------------------
  //  |     |     |     |
  //  | c-1 | c-2 | c-3 |
  //  |     |     |     |
  //  -------------------
  //  |     |     |     |
  //  | c-4 | c-5 | c-6 |
  //  |     |     |     |
  //  -------------------
  //
  //  6 equal columns for large screens and up.
  //  -------------------------------------
  //  |     |     |     |     |     |     |
  //  | c-1 | c-2 | c-3 | c-4 | c-5 | c-6 |
  //  |     |     |     |     |     |     |
  //  -------------------------------------

  //@include layout($gutter: 0.2) {
    .col-1 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 1, $output-style: 'float');
        @include make-md-column( 4 );
        padding-top: 0;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 1);
        @include make-lg-column( 2 );
      }
    }

    .col-2 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 5, $output-style: 'float');
        @include make-md-column( 4 );
        padding-top: 0;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 3);
        @include make-lg-column( 2 );
      }
    }

    .col-3 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 9, $output-style: 'float');
        @include make-md-column( 4 );
        padding-top: 0;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 5);
        @include make-lg-column( 2 );

      }
    }

    .col-4 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 1, $output-style: 'float');
        @include make-md-column( 4 );
        clear: left;
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 7);
        @include make-lg-column( 2 );
        padding-top:0;
        clear: none;
      }
    }

    .col-5 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 5, $output-style: 'float');
        @include make-md-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 9);
        @include make-lg-column( 2 );
        padding-top:0;
      }
    }

    .col-6 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        //@include grid-span(4, 9, $output-style: 'float');
        @include make-md-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        //@include grid-span(2, 11);
        @include make-lg-column( 2 );
        padding-top:0;
      }
    }
  //}

}
