$montserrat-path:     "../fonts/montserrat/";

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(#{$montserrat-path}montserrat-light-webfont.eot);
  src: local('☺'),
  url(#{$montserrat-path}montserrat-light-webfont.woff) format('woff'),
  url(#{$montserrat-path}montserrat-light-webfont.woff2) format('woff2'),
  url(#{$montserrat-path}montserrat-light-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(#{$montserrat-path}montserrat-regular-webfont.eot);
  src: local('☺'),
  url(#{$montserrat-path}montserrat-regular-webfont.woff) format('woff'),
  url(#{$montserrat-path}montserrat-regular-webfont.woff2) format('woff2'),
  url(#{$montserrat-path}montserrat-regular-webfont.svg) format('svg');
}
