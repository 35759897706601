//-----------------------
// Donut charts

// Default width/height of donut chart
$donut_size: 175px;

// Default thickness of donut ring
$donut_width: 18px;

// Base ring colour
$donut_base_colour: $gray-xlight; // Light charts
//$donut_base_colour: #464549; // Dark charts

// Centre circle colour, needs to be a solid colour
$donut_centre_colour: white;

// Colour for percentage in ring
//$donut_percent_colour: $brand_primary; // MT
$donut_percent_colour: #127eb9; // NW

// Percentage text colour
//$donut_text_colour: $donut_percent_colour; // MT
$donut_text_colour: #127eb9; // NW

// Percentage text size
$donut_text_size: 48px;