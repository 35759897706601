// Buttons
//

//** Button icon colour & icon bg-colour
@mixin button-icon-variant ($icon-color, $icon-bg-color) {
  color: $icon-color;
  //background-color: $icon-bg-color;
  background-color: darken($icon-bg-color, 5%);
}

//** Button icon padding & radius
@mixin button-icon-padding ($padding-vertical, $padding-horizontal, $border-top-right-radius, $border-bottom-right-radius) {
  padding: $padding-vertical $padding-horizontal;
  border-top-right-radius: $border-top-right-radius;
  border-bottom-right-radius: $border-bottom-right-radius;
}

//** Button colour, bg-colour & :hover bg-colour
@mixin button-variant($color, $bg-color, $bg-hover-color) {
  color: $color;
  background-color: $bg-color;

  &:hover {
    color: $color;
    background-color: $bg-hover-color;
  }
}

//@mixin button-icon-variant ($icon-color, $icon-background, $icon-darken: set-icon-darkness($icon-background)) {
//  color: $icon-color;
//  background-color: darken($icon-background, $icon-darken);
//}

//@function set-icon-darkness($color) {
//  @if ((lightness($color) > 48) and (lightness($color) < 50)) {
//    @return 2%; // balance out mid level button and icon tones
//  } @else if ((lightness($color) > 34) and (lightness($color) < 40)) {
//    @return 5%; // soften the darker button icon background
//  } @else {
//    @return 10%; // Darken all other button icons
//  }
//}

