// Primary navigation
//
$navigation-primary-bg-small:               #fff !default;
$navigation-primary-bg-medium:              #eee !default;
$navigation-primary-icon-color:             #666 !default;
$navigation-primary-divider:                '|' !default;
$navigation-primary-divider-color:          $primary-dark-color !default;
$navigation-primary-color:                  $primary-dark-color !default;
$navigation-primary-bg-active:              #fff !default;
$navigation-primary-border-color:           $gray-lighter !default;
$navigation-primary-font-size-small:        ceil(($font-size-base * 0.75)) !default;
$navigation-primary-font-size-medium:       ceil(($font-size-base * 1)) !default;

$navigation-user-divider-color:              $gray-lighter !default;
$navigation-user-bg:                         #ddd !default;
$navigation-user-bg-active:                  #ccc !default;


$navigation-sub-border-color:                 $gray-xlight !default;


// @extend %header-info-bar
//
%header-info-bar {
  color: #1d5a84;
  padding: 15px 0;
}

// @extend %header-logo
//
%header-logo {
  width: 120px;
  margin: 12px 10px 0 10px;
}
