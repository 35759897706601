// Featured Projects
//
.featured-recruiter-title{
  h1{
    max-width:65%;
    margin-top:0;
  }
  span{
    margin-top:10px;
  }
}
