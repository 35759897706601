//
// Gattaca Solutions page
//


//.page-solutions {

  .bg-sol-contact{
    background: #cbcacd;
    h2.pane-title{
      text-align:center;
    }

    //.webform-component--name, .webform-component--company, .webform-component--email, .webform-component--phone{
    //  width:50%;
    //  float:left;
    //}
  //}

}

