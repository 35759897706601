// Accordions in panels
//

// We need to cater for our icons that denote the open and closed
// state of the accordion.
.collapse-icon,
.accordion-toggle {
  &:after {
    font-size: $font-size-large;
    float: right;
    display: inline-block;
    font-family: $icon-font-family;
  }
}

[data-toggle="collapse"] {
  cursor: pointer;
}

// Accordion
.accordion {
  margin-left: -$grid-gutter-width/2;
  margin-right: -$grid-gutter-width/2;

  @include breakpoint($small-up) {
    margin-left: 0;
    margin-right: 0;
  }

  // May move these styles to the panels component sass
  // but these settings are specific to the accordion
  &.panel-group {

    .panel {
      border: 0px;
      box-shadow: none;

      // Override Bootstrap margin-top: 5px
      + .panel {
        margin-top: 2px;
      }

      .panel-heading {
        border-top: 1px solid $panel-inner-border;
        color: $accordion-default-text;

        // Override Bootstrap top border
        // Added a bottom border to panel collapse
        + .panel-collapse > .panel-body,
        + .panel-collapse > .list-group {
          border-top: none;
          border-bottom: 1px solid $panel-inner-border;
          .lead{
            color:$text-color;
            font-size:$font-size-large;
          }
        }
      }

      a.panel-heading{
        display:block;
      }

      // Override Bootstrap baked-in panel title font size
      .panel-title {
        font-size: ceil(($font-size-base * 1.428)); // 20px

        // Increased the base font awesome icon size from 18px to match the panel title
        &::after {
          font-size: ceil(($font-size-base * 1.428)); // 20px
        }
      }

      //Set a bg colour on the panel heading collapsed state
      .collapsed {
        border-top: 1px solid transparent;
        background-color: $accordion-default-heading-bg;
      }
    }
  }
}

// Accordion - Bootstrap
//.accordion {
//  margin-left: -$grid-gutter-width/2;
//  margin-right: -$grid-gutter-width/2;
//
//  @include breakpoint($small-up) {
//    margin-left: 0;
//    margin-right: 0;
//  }
//
//  // May move these styles to the panels component sass
//  // but these settings are specific to the accordion
//  .panel-group {
//
//    .panel {
//      border: 0px;
//      box-shadow: none;
//
//      // Override Bootstrap margin-top: 5px
//      + .panel {
//        margin-top: 2px;
//      }
//
//      .panel-heading {
//        border-top: 1px solid $panel-inner-border;
//        color: $accordion-default-text;
//        border-top: 1px solid transparent;
//        padding: 0;
//        //background-color: $accordion-default-heading-bg;
//
//        // Override Bootstrap top border
//        // Added a bottom border to panel collapse
//        + .panel-collapse > .panel-body,
//        + .panel-collapse > .list-group {
//          border-top: none;
//          border-bottom: 1px solid $panel-inner-border;
//
//          .lead {
//            color: $text-color;
//            font-size: $font-size-large;
//          }
//        }
//      }
//
//      //a.panel-heading {
//      //  display:block;
//      //}
//
//      // Override Bootstrap baked-in panel title font size
//      .panel-title {
//        font-size: ceil(($font-size-base * 1.428)); // 20px
//
//        .accordion-toggle {
//          background-color: $accordion-default-heading-bg;
//          display: block;
//          padding: 10px 15px;
//        }
//
//        // Increased the base font awesome icon size from 18px to match the panel title
//        &::after {
//          font-size: ceil(($font-size-base * 1.428)); // 20px
//        }
//      }
//
//      //Set a bg colour on the panel heading collapsed state
//      //.collapsed {
//      //  border-top: 1px solid transparent;
//      //  background-color: $accordion-default-heading-bg;
//      //}
//    }
//  }
//}

// Icon themes variants
//
//** Plus and Minus icons
@include accordion-icon-variant('collapse-icon-default', $fa-var-plus, $fa-var-minus);

//** Chevron down and Chevron up
@include accordion-icon-variant('collapse-icon-arrow', $fa-var-chevron-down, $fa-var-chevron-up);

//** angle down and angle up
@include accordion-icon-variant('collapse-icon-angle', $fa-var-angle-down, $fa-var-angle-up);