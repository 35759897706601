// Primary nav
//
#nav-primary,
.nav-primary {
  background: $navigation-primary-bg-small;
  border-bottom: 1px solid $navigation-primary-border-color;

  .shown-st {
    display: none;
  }

  &.stuck {
    box-shadow: 0 0 10px $gray;
    background: $navigation-primary-bg-small;

    .logo {
      display: inline-block;
      overflow: hidden;
      width: 0;

      .img-responsive {
        max-width: none;
        width: 130px;
      }
    }

    .hidden-st {
      display: none;
    }

    .shown-st {
      display: block;
    }
  }

  @include breakpoint($small-up) {
    padding: 0;
    border: 0;
  }

  @include breakpoint($medium-up) {
    background: $navigation-primary-bg-medium;
    padding: 0;
    font-size: $navigation-primary-font-size-small;
    margin: 0;
  }

  @include breakpoint($large-up) {
    font-size: $navigation-primary-font-size-medium;
  }

  //.hotdog-nav,
  //.logo,
  //.strapline {
  //  display: inline-block;
  //  float: left;
  //
  //  @media only screen and (min-width: 1025px) {
  //    display: none;
  //  }
  //}

  .hotdog-nav {
    padding: 15px 0 10px 0;

    .fa {
      font-size: $font-size-xlarge;
    }
  }

  // Company strap-line
  .strapline {
    color: $header-info-strapline-color-sm;
    display: none;
    font-size: $font-size-small;
    margin: 0;
    padding-top: 20px;
    padding-left: 20px;
    text-align: left;

    @include breakpoint($small-up) {
      display: inline-block;
    }

    @include breakpoint($medium-up) {
      display: none;
    }
  }

  // Brand logo
  .logo {
    @extend %header-logo;
  }

  .fa {
    bottom: -2px;
    color: $navigation-primary-icon-color;
    font-size: $font-size-large;
    position: relative;
    margin-top: -4px;
  }

  //.navbar-collapse {
  //  padding: 0;
  //
  //  &.collapse {
  //    display: none !important;
  //    float: left;
  //
  //    @media only screen and (min-width: 1025px) {
  //      display: inline-block !important;
  //    }
  //  }
  //}

  .nav {
    padding: 0 0 0 5px;

    > li {

      // puts a divider between main navigation elements
      &:after {
        color: $navigation-primary-color;
        content: $navigation-primary-divider;
        float: left;
        font-weight: $font-weight-light;
        font-size: 20px;
        padding-top: 10px;
        margin: 0 0 0 -4px;
      }

      &:nth-last-child(2):after,
      &:nth-last-child(1):after {
        content: "";
      }

      > a {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 0 0 -5px;
        color: $navigation-primary-color;

        @include breakpoint($large-up) {
          padding-left: 20px;
          padding-right: 20px;
        }

        &:hover, &:active, &.active, &.active-trail {
          background-color: $navigation-primary-bg-active;
          border-color: $navigation-primary-border-color;
        }
      }

      &.open {
        > a {
          background-color: $navigation-primary-bg-active;
          border-color: $navigation-primary-border-color;
        }
      }
    }

    // overriding status.scss
    .disabled {
      opacity: 1;
    }

    .dropdown-menu {
      left: -4px;
      border: 0;
      background:$navigation-primary-bg-small;
      a{
        color:$navigation-primary-color;
      }
    }

    // Bootstraps dropdown font sizes are set separately from the parent level menu items so have to be overridden
    // The following rule is executed at root level and overrides all dropdowns
    @at-root .dropdown-menu {
      font-size: inherit;
      padding-top:0;
      @include box-shadow(0 12px 12px rgba(0, 0, 0, .175));

      > li {
        > a {
          color: $link-color;
          padding-top:10px;
          padding-bottom:10px;
        }
      }
      .divider{
        margin:0;
        background-color:$navigation-sub-border-color;
      }
    }

    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

  // User nav
  // This nav contains register, login, favourite and shortlist
  .user-nav {
    display: inline-block;
    float: right;

    .nav {
      margin: 0;
      margin-right: -($grid-gutter-width/2);

      @include breakpoint($medium-up) {
        margin-right: 0;
      }

      &.internal {
        padding-left: 0;
        margin-right: 0;
      }

      font-size: 1px;

      > li {
        display: inline-block;

        &:after {
          content: $navigation-primary-divider;
          font-size: 20px;
          float: left;
          color: $navigation-user-divider-color;
          padding-top: 10px;
          font-weight: $font-weight-light;
        }
        &:nth-last-child(1)::after {
          content: "";
          @include breakpoint($medium-up) {
            content: $navigation-primary-divider;
          }
        }

        > a {
          padding: 18px 15px 11px 15px;
          float: left;
        }

        @include breakpoint($not-large) {
          &.login-register {
            & a > span {
              display: none;
            }
          }
        }

        @include breakpoint($large-up) {
          &.login-register {

            font-size: $navigation-primary-font-size-medium;

            &:after {
              content: "";
            }

            > a {
              padding: 15px 15px 16px 15px;
              margin-right: 2px;
              background-color: $navigation-user-bg;

              > span {
                padding-left: 10px;
              }

              &:hover,
              &:focus {
                background-color: $navigation-user-bg-active;
              }
            }
          }
        }

        &.register {
          font-size: $navigation-primary-font-size-small;

          @include breakpoint($large-up) {
            font-size: $navigation-primary-font-size-medium;
          }

          &:after {
            content: "";
          }

          > a {
            padding: 15px 15px 16px 15px;
            margin-right: 2px;
            background-color: $navigation-user-bg;

            > span {
              padding-left: 10px;
            }

            &:hover,
            &:focus {
              background-color: $navigation-user-bg-active;
            }
          }
        }

        &.shortlist {
          > a {
            height: 50px;
          }
        }

        &.login.internal {
          > a {
            font-size: 14px;
            padding-top: 16px;
            height: 50px;

            > span {
              margin-left: 8px;
            }
          }
        }

        &.register.internal {
          > a {
            margin-left: 0;
            margin-right: 0;

            > span {
              padding-left: 0;
            }
          }
        }

        .add-to-shortlist {
          &.selected {
            i {
              color: $icon-shortlist-selected-color;
            }
          }
        }
      }
    }
  }

  // Dropdown submenu in a nav list item
  //
  .dropdown-submenu {
    position: relative;

    .fa {
      font-size: $font-size-small;
      color: $navigation-primary-color;
      bottom: auto;
      left: 5px;
    }
  }

  .dropdown-submenu > .dropdown-menu {
    top: 100%;
    left: -4px;
    display: none;
    background:$navigation-primary-bg-small;
  }

  .dropdown-submenu > .dropdown-menu .dropdown-menu {
    top: -2px;
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}

// Main nav will display the off-canvas nav on any size touch device
// and on desktop browsers below 1025px in width.
#nav-primary {
  .hotdog-nav,
  .logo,
  .strapline {
    display: inline-block;
    float: left;
  }

  .navbar-collapse {
    padding: 0;

    &.collapse {
      display: none !important;
      float: left;
    }
  }

  @media (min-width: 1025px) {
    .hotdog-nav,
    .logo,
    .strapline {
      display: none;
    }

    .navbar-collapse {
      &.collapse {
        display: inline-block !important;
      }
    }
  }

  &.touch-device {
    .hotdog-nav,
    .logo,
    .strapline {
      display: inline-block;
    }

    .navbar-collapse {
      &.collapse {
        display: none !important;
      }
    }
  }
}
