// Search job sectors
//

.search-job-sectors {

  h3 {
    font-weight: $font-weight-bold;
    color:#fff;
    margin-top:0;
  }

  .list-job-sectors {
    text-align: center;

    // Padding used for vertical alignment below search form
    @include breakpoint($small-up) {
      padding-left: 10%;
      padding-right: 10%;
    }

    li {
      padding: 0;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;

      @include breakpoint($medium-up) {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
      }

      // Increase button size for different screens
      .btn {
        font-size: $font-size-medium;
        color: $search-sectors-text-color;

        @include breakpoint($small-up) {
          font-size: $font-size-medium;
        }

        @include breakpoint($medium-up) {
          font-size: $font-size-large;
        }
      }
    }
  }
}