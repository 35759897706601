$geometria-path:     "../fonts/geometria/";

@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  src: url(#{$geometria-path}geometria-regular-webfont.eot);
  src: local('☺'),
  url(#{$geometria-path}geometria-regular-webfont.woff) format('woff'),
  url(#{$geometria-path}geometria-regular-webfont.woff2) format('woff2');
}

@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  src: url(#{$geometria-path}geometria-medium-webfont.eot);
  src: local('☺'),
  url(#{$geometria-path}geometria-medium-webfont.woff) format('woff'),
  url(#{$geometria-path}geometria-medium-webfont.woff2) format('woff2'),
  url(#{$geometria-path}geometria-medium-webfont.svg) format('svg');
}
