//
// Voice of the Workforce variables for Matchtech
//

$vow-section-heading-bg:              $brand-primary;
$vow-section-heading-color:           white;
$vow-section-heading-font-weight:     500;

$vow-section-intro-bg:                #3b3b3e;
$vow-section-accordion-bg:            #464549;

$vow-heading-color-default:           white;
$vow-heading-color-primary:           $brand-primary;
$vow-heading-color-secondary:         $secondary-base-color;

$get-path-to-assets:                  "/sites/default/files/wysiwyg/images/vow/2017";