// Back-to-top link
//

a.back-to-top {
  background: $back-to-top-bg;
  color: $back-to-top-color;
  position: fixed;
  padding: 10px 23px;
  text-decoration: none;
  z-index: 100;
  display:none;
  bottom: 0;
  right: 0;
  opacity:$back-to-top-opacity;
  @include breakpoint($medium-up) {
    bottom: 40px;
    right: 40px;
  }
}