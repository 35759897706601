// Typography
//

.main-content ul {
  list-style: none;
  margin:10px 0 0 15px;
  padding:0;
  li {
    &:before {
      content: "•";
      color: $secondary-dark-color;
      position:absolute;
      margin-left:-18px;
      margin-top:-3px;
      font-size:$bullet-size;
      line-height:25px;
    }
    padding:0 0 10px 10px;
  }
}

.main-content ol {
  margin:10px 0 0 25px;
  padding:0;
  li {
    padding:0 0 10px 0;
  }
}

// Informational/Instructional text
.text-primary {
  color: $brand-primary;
}

// Warning/Error text
.text-accent {
  color: $accent-base-color;
}

// White text
.text-white {
  color: #fff!important;
}

// Link wrappers with no default text decoration
.link-no-decoration {
  text-decoration: none;
}

.heading-border {
  text-align: center;
  position: relative;
  //margin-bottom: 20px;
  padding-top: 15px;
  @include breakpoint($medium-up) {
    padding-top: 0;
  }

  // Override bottom margin if used within a 1/3 width column
  @at-root .row-2-1 .col-2 & {
    margin-bottom: 0;
  }

  @include breakpoint($medium-up) {
    border-top: 1px solid $gray-xlight;
    margin-top: -1px !important;

    h1, h2, h3, h4, h5 {
      background: white;
      display: inline-block;
      padding: 0 $grid-gutter-width/2;
      position: relative;
      top: -($font-size-h1/1.7);
      margin: 0;
    }
    h2 {
      top: -($font-size-h2/1.7);
    }
    h3 {
      top: -($font-size-h3/1.7);
    }
    h4 {
      top: -($font-size-h4/1.6);
    }
    h5 {
      top: -($font-size-h5/1.5);
    }
  }
}

// Horizontal rule spacing
.hr-v-margin-small {
  margin-top: 10px;
  margin-bottom: 10px;
}

// Horizontal rule partial width
.hr-partial-width {
  max-width: 90px;
  border-top: 2px solid $secondary-base-color;
  margin-left: 0;
  text-align: left;
}

// Center headings
//h1, h2, h3, h4, h5, h6 {
//
//  &.heading-center {
//    text-align: center;
//    margin-bottom: 40px;
//  }
//}

// Section heading
.section-heading {
  text-align: center;
  margin-bottom: 10px;

  @include breakpoint($medium-up) {
    margin-bottom: 40px;
  }
}

// Section link
.section-link {
  padding: 40px 0;

  // Centers link in a section div
  .btn-link {
    margin: 0 auto;
  }
}

//** Lead text overrides TWBS default styles
.lead {
  color: $gray-light;
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.15)); // 16px
  font-weight: $font-weight-regular;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    //font-size: ceil(($font-size-base * 1.57)); // 22px
    font-size: ceil(($font-size-base * 1.42)); // 20px
  }
}

//** Additional lead class for slightly larger text.
.lead-lg {
  @extend .lead;
  font-size: floor(($font-size-base * 1.7)); // 23px
}

//** Icon list default font size
ul.fa-ul {
  font-size: $font-size-large;

  @include breakpoint($medium-up) {
    font-size: $font-size-medium;
  }

  li {
    margin-bottom: 10px;

    .fa {
      color: $fa-icon-color;
    }
  }
}

//** Address default font size
address {
  font-size: $font-size-medium;
}

// TODO: To be removed only testing font family rendering on the font test page
//
//** Montserrat font
.font-montserrat {
  font-family: 'Montserrat', sans-serif;

  &.light-weight {
    font-weight: 300;
  }
}

//** Geometria font
.font-geometria {
  font-family: 'Geometria', sans-serif;

  &.medium-weight {
    font-weight: 500;
  }
}

//** Roboto font
.font-roboto {
  font-family: 'Roboto', sans-serif;

  &.bold-weight {
    font-weight: 700;
  }
}

//** Verdana font
.font-verdana {
  font-family: 'Verdana', sans-serif;

  &.bold-weight {
    font-weight: 700;
  }
}

//** TODO: This should be moved from here...
.home-intro {
  display: none;

  @include breakpoint($small-up) {
    display: block;
    font-size: $font-size-small-intro;
    letter-spacing: .07em;
  }

  @include breakpoint($medium-up) {
    font-size: $font-size-medium-intro;
    padding-left: 20%;
    padding-right: 20%;
  }

  @include breakpoint($large-up) {
    font-size: $font-size-large-intro;
  }

  color: #fff;

  strong {
    font-size: 1.7em;
  }
}
