// Alternative nav
//
#nav-primary.alternative {

  //background: $navigation-alt-bg-small;
  background: #8a8a8e;

  .logo {
    width: auto;
    padding-bottom: 10px;
    display: block;
    .img-responsive {
      width: 130px;
    }
  }

  &.stuck {
    .logo {
      display: block;
      overflow: visible;
      width: auto;
      .img-responsive {
        width: 130px;
      }
    }
  }

  .nav {
    padding: 0 0 0 5px;

    > li {

      // puts a divider between main navigation elements
      &:after {
        //color: $navigation-alt-color;
        content: '';
      }

      > a {
        //color: $navigation-alt-color;
        color: #fff;
        text-transform: uppercase;
        font-size: floor($font-size-h6);
        padding-top: 5px;
        padding-bottom: 5px;
        &:hover, &:active {
          //background-color: $navigation-alt-bg-active;
          //border-color: $navigation-alt-border-color;
          color: $text-color;
        }
        &.active, &.active-trail {
          //background-color: #666;
          //border-color: #666;
          color: $text-color;
        }
      }

      &.open {
        > a {
          //background-color: $navigation-alt-bg-active;
          //border-color: $navigation-alt-border-color;
          color: #000;
        }
      }

    }

    .dropdown-menu {
      a {
        color: $primary-base-color;
      }
    }

  }

  .navbar-collapse {
    &.collapse {
      float: right;
    }
  }

  &.stuck {
    //background: $navigation-alt-bg-small;
    background: #8a8a8e;
  }

  .nav-area {
    .fa {
      color: #fff;
    }
  }

  .sub-area {
    background: #fff;
    border-bottom: 1px solid #f8f8f8;

    .nav {
      float: right;
      > li {

        > a {
          color: $text-color;
          font-size: floor($font-size-h5);
          font-weight: 500;
          padding-top: 25px;
          padding-bottom: 20px;
          text-transform: none;
          max-width: 150px;
          &:hover, &:active, &.active {
            color: $primary-base-color;
            border-color: $gray-xlight;
          }
        }

        &.open {
          > a {
            color: $primary-base-color;
            border-color: $gray-xlight;
          }
        }

      }
      .dropdown-submenu {
        a {
          padding-right: 30px;
          position: relative;
          .caret {
            position: absolute;
            right: 15px;
            top: 33px;
          }
        }

      }

      .dropdown-menu {
        a {
          color: $primary-base-color;
        }
      }

      // re-iterating the bootstrap class as not specific enough in this use case.
      .dropdown-menu-right {
        left: auto;
      }

    }

  }

}
