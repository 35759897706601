// Job Manager panel
//
.panel-job-manager {
  background: $panel-job-manager-bg;
  border: 0;

  h3 {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .panel-body {
    font-size: $font-size-large;
    color: $panel-job-manager-color;
    padding-top: 30px;
    @extend %heading-margin-reset;

    .img-profile {
      border-bottom: 5px solid $secondary-base-color;
    }
  }

  .panel-footer {
    background: $panel-job-manager-bg;
    text-align: center;
    border-color: $panel-job-manager-border;

    p {
      padding-top: 15px;

      strong {
        color: $panel-job-manager-foot-color;
        font-size: $font-size-large;
        font-weight: 400;
      }
    }

    .btn {
      margin: 10px 0;
      white-space: normal;
    }

    .btn-with-icon {
      display: inline-table;
    }
  }
}