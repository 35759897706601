// Bar itself
// -------------------------
//** Overrides Bootstrap default styles

// Outer container
.progress {
  height: 25px;
}

// Bar of progress
.progress-bar {
  line-height: 28px;
}