// 2 columns left
//
.row-1-2 {
  @include make-row( 30px );
  /*
  2 column row with 1 third / 2 thirds split spans.
  For small screens the columns become vertically stacked
  in the order they appear in the markup.
  -------------------------------
  |         |                   |
  |  col-1  |       col-2       |
  |         |                   |
  -------------------------------
  */
  > .col-1 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 1);
      @include make-md-column( 4,30px );
    }
  }

  > .col-2 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(8, 5);
      @include make-md-column( 8,30px );
    }
  }

  @extend %row-stack-spacing-medium-up;
}