// Masonry grid
//

.masonry-grid {

  // Add vertical spacing between columns at small only screens
  .col-v-margin {
    @include breakpoint($small-only) {
      margin-top: $grid-gutter-width !important;
    }
  }

  // Masonry blocks
  .masonry-block {
    background-color: $primary-light-color;
    position: relative;
    height: 230px;
    overflow: hidden;

    @include breakpoint($medium-up) {
      height: 135px;
    }

    // Block title overlay with rounded bg
    .block-title-overlay {
      background-color: #000; // IE8 fallback
      background-color: $block-title-overlay-bg;
      border-top-right-radius: $block-title-overlay-radius;
      border-bottom-right-radius: $block-title-overlay-radius;
      font-size: $masonry-font-size;
      font-weight: $block-title-font-weight;
      color: $block-title-font-colour;
      position: absolute;
      bottom: 10px;
      left: 0;
      padding: $block-title-overlay-padding;
      max-width: 90%;

      span {
        display: block;
        font-weight: normal;
        font-size: $masonry-span-font-size;
      }
    }

    &:hover .block-title-overlay {
      background-color: #000; // IE8 fallback
      background-color: $block-title-overlay-bg-hover;
    }
  }

  // Masonry tall blocks
  .masonry-block-tall {
    background-color: $primary-base-color;

    @include breakpoint($medium-up) {
      height: 300px;
    }
  }

  // Masonry bg images for all masonry blocks
  .masonry-block-1 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
  .masonry-block-2 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
  .masonry-block-3 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
  .masonry-block-4 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
  .masonry-block-5 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
  .masonry-block-6 {
    background: url($masonry-bg-img) no-repeat 0 center; // Fall-back original bg img size
    @include image-15x($masonry-bg-img, 480px, 727px); // Retina bg img @1.5x size
    background-size: 100%;
  }
}

// Small Masonry grid
//
.masonry-grid-small {

  // Masonry blocks
  .masonry-block {

    @include breakpoint($medium-up) {
      height: 85px;
    }

    // Block title overlay with rounded bg fill
    .block-title-overlay {
      padding: $block-title-overlay-padding;

      @include breakpoint($medium-up) {
        font-size: $masonry-font-size-small;
        padding: $block-title-overlay-padding-small;
      }
      @include breakpoint($large-up) {
        font-size: ($masonry-font-size-small+2);
      }

      span {
        @include breakpoint($medium-up) {
          font-size: $masonry-span-font-size-small;
        }
        @include breakpoint($large-up) {
          font-size: ($masonry-span-font-size-small+2);
        }
      }
    }
  }

  // Masonry tall blocks
  .masonry-block-tall {

    @include breakpoint($medium-up) {
      height: 200px;
    }
  }
}

