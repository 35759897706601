// Background hero title
//

.background-title.hero {

  background-color: $background-title-hero-bg;

  h1.title {
    padding: 150px 0 25px 0;
    color: #fff;
    text-align: center;
    font-size: floor($font-size-h1);
  }

  p.title {
    padding: 196px 0 25px 0;
    color: #fff;
    text-align: center;
    font-size: floor($font-size-h1);
  }

  background-position: center center;

  @include breakpoint($xxsmall-only) {
    background-size: 768px auto;
  }

  @include breakpoint($xsmall-up) {
    h1.title {
      text-align: left;
    }
  }

}

