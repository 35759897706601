// Form filter input
//

.filter-input {

  input {
    border: 1px solid $form-filter-input-border;
    // stop ie8 fields overflowing their container (not perfect but satisfactory to make ie8 functional).
    min-width: 90%;
    max-width: 100%;
    margin:0 0 15px 0;

    &:disabled {
      background: none;
    }
  }
}