/* Typography styling */
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  color: $header-font-color;
  font-weight:$headings-font-weight;
}

//overwrite bootstrap heading margins for larger headings
h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 0;
  margin-bottom: $line-height-computed;
}

body{
  font-size:$font-size-base*$paragraph-font-reduction-sm;
  @include breakpoint($medium-up) {
    font-size:$font-size-base*$paragraph-font-reduction-md;
  }
}

/* Typography resets */
//ul.unstyled,
//ol.unstyled {
//  margin: 0;
//  padding: 0;
//  list-style: none;
//}

p {
  //margin-bottom: $line-height-computed / 1.05+px;
}

/* Default Link Styles */
a {

  /* Disabled state sets text to gray and nukes hover/tab effects */
  &[disabled] {
    color: $nav-disabled-link-color;
    text-decoration: none;
    opacity: 0.7;

    &:hover,
    &:focus {
      color: $nav-disabled-link-hover-color;
      text-decoration: none;
      background-color: transparent;
      cursor: $cursor-disabled;
    }
  }
}
