// Re-usable standard grid spans
// 2 columns
//
.row-1-1 {
  /*
  2 column row with equally split columns.
  For xsmall screens the columns become vertically stacked
  in the order they appear in the markup.
  -----------------------------
  |             |             |
  |    col-1    |    col-2    |
  |             |             |
  -----------------------------
  */

  > .col-1 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include breakpoint($xsmall-up, $no-query: true) {
      @include make-xs-column( 6 ); // Bootstrap make column
    }

    //@include breakpoint($small-up, $no-query: true) {
    //  @include make-sm-column( 6 ); // Bootstrap make column
    //}
  }

  > .col-2 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include breakpoint($xsmall-up, $no-query: true) {
      @include make-xs-column( 6 ); // Bootstrap make column
    }

    //@include breakpoint($small-up, $no-query: true) {
    //  @include make-sm-column( 6 ); // Bootstrap make column
    //}
  }

  @extend %row-stack-spacing-xsmall-up;
}

// 2 columns on small-only screen size
//
.row-1-1-small-only {
  /*
  2 column row with equally split columns.
  For small screens the columns become vertically stacked
  in the order they appear in the markup.
  -----------------------------
  |             |             |
  |    col-1    |    col-2    |
  |             |             |
  -----------------------------
  */
  > .col-1 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include breakpoint($small-only) {
      //@include grid-span(6, 1);
      @include make-sm-column( 6 );
    }
  }

  > .col-2 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px
    margin-top: $layout-stacked-gutter;

    @include breakpoint($small-only) {
      //@include grid-span(6, 7);
      @include make-sm-column( 6 );
      margin-top: 0;
    }
  }
}

// 2 columns on medium up screen size
//
.row-1-1-medium-up {
  /*
  2 column row with equally split columns.
  For xsmall and small screens the columns become vertically stacked
  in the order they appear in the markup.
  -----------------------------
  |             |             |
  |    col-1    |    col-2    |
  |             |             |
  -----------------------------
  */
  > .col-1 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(6, 1);
      @include make-md-column( 6 );
    }
  }

  > .col-2 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(6, 7);
      @include make-md-column( 6 );
    }
  }

  @extend %row-stack-spacing-medium-up;
}

// 2 columns on all screen sizes including the smallest
//
.row-1-1-all {
  /*
  2 column row with equally split columns.
  -----------------------------
  |             |             |
  |    col-1    |    col-2    |
  |             |             |
  -----------------------------
  */
  > .col-1 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include make-xs-column( 6 );
  }

  > .col-2 {
    padding-left: $grid-gutter-width / 2; // 15px
    padding-right: $grid-gutter-width / 2; // 15px

    @include make-xs-column( 6 );
  }

}