// Tables
//

//** Table themes
//
// Primary theme
// Sets the default table content font size
.table-primary {
  font-size: $font-size-medium;
  margin-bottom: 20px;

  // Table heading styles
  th {
    background-color: $table-heading-bg;
    color: $table-heading;
    font-weight: normal;
  }

  // Defines table border colours
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        border-bottom-color: white;
      }
    }

    // Resets the last row bottom border colour to default colour
    > tr:last-child {
      > th {
        border-bottom-color: $table-border-color;
      }
    }
  }
}

//** Fixed width cells
.fixed-width-30 {
  th {
    width: 30%;
  }
}

.fixed-width-50 {
  th {
    width: 50%;
  }
}
