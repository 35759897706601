// Filter listing
//

.filter-listing {

  select {
    border-color: $primary-light-color;

    &.input-lg {
      line-height: inherit;
    }
  }

  .input-icon {
    color: $primary-base-color;
  }

  .col-2 {
    margin-top: 10px;
  }

  @include breakpoint($xsmall-up) {
    .col-1 {
      padding-left: 0;
    }

    .col-2 {
      padding-right: 0;
      margin-top: 0;
    }
  }
}