// Share bar
//
.share-bar {
  .list-utils {
    text-align: center;
    margin:5px 0;
    @include breakpoint($medium-up) {
      text-align: right;
    }
  }

  .list-share {
    text-align: left;
    li {
      padding-right: 0;
      a {
        padding: 4px;
        width: 30px;
        .fa-lg{
          font-size:$font-size-medium;
        }
      }
    }
  }

}
