// Static slider
//

.static-slider {
  position: fixed;
  right: 0;
  top: 60%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);

  span {
    position: fixed;
    bottom: 3px;

    a {
      box-shadow: -3px 3px 10px rgba(0,0,0,0.5);
    }
  }
}
