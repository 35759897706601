// overwriting bootstrap defaults due to lack of responsive support
.media-right,
.media > .pull-right {
  float: none !important;
  padding: 0;
  @include breakpoint($xsmall-up) {
    float: right !important;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}
// overwriting bootstrap defaults due to lack of responsive support
.media-left,
.media > .pull-left {
  float: none !important;
  padding: 0;
  @include breakpoint($xsmall-up) {
    float: left !important;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
// overwriting bootstrap defaults as content feels squashed without spacing on headings in content areas by default
.media h1,
.media h2,
.media h3,
.media h4,
.media h5 {
  margin-top: 20px;
}