// Favourite Jobs
//
.form-favourite-jobs {

  // Adds padding around checkboxes and trash icon
  .select,
  .remove {
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
  }

}