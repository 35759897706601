// 2 columns right
//
.row-2-1 {
  /*
  2 column row with 2 thirds / 1 third split spans.
  For small screens the columns become vertically stacked
  in the order they appear in the markup.
  -------------------------------
  |                   |         |
  |       col-1       |  col-2  |
  |                   |         |
  -------------------------------
  */
  > .col-1 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(8, 1);
      @include make-md-column( 8 );
    }
  }

  > .col-2 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 9);
      @include make-md-column( 4 );
    }
  }

  @extend %row-stack-spacing-medium-up;
}