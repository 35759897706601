$roboto-path:     "../fonts/roboto/";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url(#{$roboto-path}roboto-thin-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-thin-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-thin-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-thin-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(#{$roboto-path}roboto-light-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-light-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-light-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-light-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(#{$roboto-path}roboto-regular-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-regular-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-regular-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-regular-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(#{$roboto-path}roboto-medium-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-medium-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-medium-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-medium-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(#{$roboto-path}roboto-bold-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-bold-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-bold-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-bold-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(#{$roboto-path}roboto-black-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-black-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-black-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-black-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url(#{$roboto-path}roboto-thinitalic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-thinitalic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-thinitalic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-thinitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url(#{$roboto-path}roboto-lightitalic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-lightitalic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-lightitalic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-lightitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url(#{$roboto-path}roboto-italic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-italic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-italic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-italic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url(#{$roboto-path}roboto-mediumitalic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-mediumitalic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-mediumitalic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-mediumitalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(#{$roboto-path}roboto-bolditalic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-bolditalic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-bolditalic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-bolditalic-webfont.svg) format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url(#{$roboto-path}roboto-blackitalic-webfont.eot);
  src: local('☺'),
  url(#{$roboto-path}roboto-blackitalic-webfont.woff) format('woff'),
  url(#{$roboto-path}roboto-blackitalic-webfont.woff2) format('woff2'),
  url(#{$roboto-path}roboto-blackitalic-webfont.svg) format('svg');
}
