// Status
//

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  input {
    pointer-events: none;
  }
}
