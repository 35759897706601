// Off canvas
//
// Contains entire page to allow menu to slide off page without increasing the site width
.offcanvas-wrap {
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.offopen {
  overflow-x: hidden;
}

.offcanvas {
  position: relative;
  height: 100%;
  //@include translate(0, 0);

  &.in {
    @include translate($offcanvas-content-width, 0);

    @include breakpoint($small-up) {
      @include translate(45%, 0);
    }
  }
}

// Contain the content of the site seperately to the offcanvas menu to allow alignment
.offcanvas-panel {
  position: absolute;
  z-index: $zindex-offcanvas-panel;
  top: 0;
  width: $offcanvas-content-width;
  left: -$offcanvas-content-width;
  background-color: $offcanvas-content-bg;
  margin-top: -9999em;

  @include breakpoint($small-up) {
    width: 45%;
    left: -45%;
  }
}

// The header part of the off canvas panel
.offcanvas-header {
  text-align: right;
  padding: $offcanvas-header-padding;

  // close button specific to the offcanvas menu
  .close-oc {
    @include font-size(26);
    color: $gray;
    cursor: pointer;

    &:hover .fa {
      color: #000;
    }
  }
}

// The body part of the off canvas panel, usually contains the menu itself
.offcanvas-body {
  background-color: $offcanvas-content-bg;
  padding: $offcanvas-body-padding;

  .nav {
    .open {
      > a {
        &:hover, &:focus {
          background-color: transparent;
        }
        &.caret:hover, &.caret:focus {
          background-color: transparent;
        }
      }
      .dropdown-menu {
        a {
          padding-left: 15px;
          background-color: transparent;
        }
      }
    }
  }

  > .navbar-nav {
    margin: 0;
    border-top: 1px solid $secondary-base-color;
    font-size: $font-size-large;
    float: none;

    ul {
      padding-left: 5px;
    }

    > li {
      float: none;

      > a {
        border-bottom: 1px solid $secondary-base-color;
        background: $gray-xlight;
        padding-top: 15px;
        padding-bottom: 15px;
        &.caret {
          position: absolute;
          z-index: 3;
          right: 0;
          top: 0;
          border: 0;
          background: none;
          &:hover, &:focus, &:active {
            background: none;
          }
          &:after {
            font-size: $font-size-large;
            float: right;
            display: inline-block;
            font-family: $icon-font-family;
            content: $fa-var-chevron-down;
          }
        }
      }

      &.login-register > a {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #ddd;

        > i {
          padding-right: 10px;
        }
      }

      &.register > a {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #ddd;

        > i {
          padding-right: 10px;
        }

        &:hover,
        &:focus {
          //background-color: #ccc;
        }
      }

      .dropdown-submenu {
        & a .fa {
          //display: none;
        }
        &.open a .fa {
          display: inline-block;
        }
      }

    }

    > li {
      float: none;

      > a {
        border-bottom: 1px solid $secondary-base-color;
        background: $gray-xlight;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &.register > a {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #ddd;

        &:hover,
        &:focus {
          //background-color: #ccc;
        }
      }

      .dropdown-submenu {
        & a .fa {
          //display: none;
        }
        &.open a .fa {
          display: inline-block;
        }
      }

    }

    li {
      position: relative;
      a {
        &.caret {
          position: absolute;
          z-index: 3;
          right: 0;
          top: 0;
          border: 0;
          background: none;
          height: 50px;
          width: 50px;
          &:after {
            font-size: $font-size-large;
            float: right;
            display: inline-block;
            font-family: $icon-font-family;
            content: $fa-var-chevron-down;
          }
        }
      }
      &.open {
        > a {
          &.caret {
            &:after {
              content: $fa-var-chevron-up;
            }
          }
        }
      }

    }

    .dropdown-menu > li > a, .dropdown-menu .dropdown-header {
      padding-top: 15px;
      padding-bottom: 15px;

      &:hover,
      &:focus {
        //background-color: #ccc;
      }
    }

    // change default bootstrap behaviour on request to wrap all levels
    li a {
      white-space: normal;
    }

    .dropdown-menu .divider {
      background-color: $offcanvas-header-border-color;
    }
  }

}

// Content overlay, created by javascript when the offcanvas is active
// Stops interaction with content while the menu is visible.
// Can be clicked to close offcanvas menu too.
.offcanvas-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: $offcanvas-backdrop-opacity;
  background-color: $offcanvas-backdrop-bg;
  z-index: $zindex-offcanvas-background;
  cursor: pointer;
}

// Shame list...
.lt-ie9 {
  // Allow the offcanvas wrapper to scroll horizontally for
  // browsers (IE 8) that are not responsive (support Media Queries)
  // and are using the Breakpoint no-query CSS approach.
  .offcanvas-wrap {
    //overflow-x: visible;
  }

  // Basic support for browsers not supporting translate (IE 8)
  .offcanvas {
    position: relative;
    left: 0;

    &.in {
      left: $offcanvas-content-width;
    }
  }
  .offcanvas-backdrop {
    visibility: hidden;
  }
}

// shame
.lt-ie10 .offcanvas-wrap {
  overflow-y: hidden;
}


// Overrides TWBS navbar-nav dropdown menus behaviour removing the breakpoint
// to allow the dropdown menus display full-width in the off-canvas nav.
.offcanvas-panel {
  .navbar-nav {

    // Overriding the TWBS breakpoint so these styles are applied to all viewports
    //@media (max-width: $grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        > li > a,
        .dropdown-header {
          padding: 15px 15px 15px 25px;
        }
        > li > a {
          line-height: $line-height-computed + px;
          &:hover,
          &:focus {
            background-image: none;
          }
        }
      }
    //}
  }
}
