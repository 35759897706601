//
// Template structure for overwriting a client section of the Matchtech Website
//

.page-featured-recruiters {

  //font-family: "TimesNewRoman", Times New Roman, Times, Baskerville, Georgia, serif;
  //font-family: "Georgia", Times, Times New Roman, serif;
  //font-family: "Verdana", Geneva, sans-serif;
  //font-family: "Tahoma", Verdana, Segoe, sans-serif;
  font-family: "Arial", Helvetica Neue, Helvetica, sans-serif;

  color: #3d3935;

  .lead, .lead-lg {
    color: #3d3935;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #00507f;
  }

  .btn-primary {
    color: #3d3935;
    background-color: #c5e86e;
    .btn-icon {
      background-color: darken(#c5e86e, 20%);
      color: #3d3935;
    }
    &:hover {
      color: #3d3935;
      background-color: darken(#c5e86e, 20%);
    }
    &:active {
      color: #3d3935;
      background-color: darken(#c5e86e, 30%);
    }
  }

  .filter-listing select {
    border-color: #c8dde7;
    .border-bottom-gray {
      border-bottom: 1px solid #e6e6e6;
    }
    .border-top-gray {
      border-top: 1px solid #e6e6e6;
    }
  }

  .listing-heading {
    .list-utils {
      li {
        a {
          color: #949ca1;
        }
      }
    }
  }

  .job-listings {
    .listing {
      .listing-header {
        background-color: #d6d7d8;
        h3 {
          a {
            color: #3d3935;
          }
          .label-justadded {
            background-color: #da961a;
          }
          .label-featured {
            background-color: #005b85;
          }

        }
        .add-to-shortlist {
          background-color: #e4e4e4;
          i {
            color: #949ca1;
          }
        }
      }
      .listing-content {
        ul {
          li {
            color: #00507f;
            &::before {
              color: #949ca1;
            }
          }
        }
      }
    }
  }

  .previous-next {
    .btn {
      font-weight: 100;
    }
  }

  .offcanvas-panel {
    background-color: black;
  }

  .border-top-orange {
    border-top: 3px solid #e59500;
  }

  .bg-default {
    background-color: #eeeeee;
  }

  .footer-legal {
    p {
      color: #909090;
    }
    .footer-legal-nav li a {
      color: #909090;
    }
  }

  .btn, #sliding-popup .popup-content #popup-buttons button {
    font-size: 16px;
  }

  .btn-cancel {
    color: #0076b0;
    background-color: #eeeeee;
  }

  // Accordion
  .accordion {
    &.panel-group {
      .panel {
        .panel-heading {
          border-color: $panel-inner-border;
          color: $accordion-default-text;

          + .panel-collapse > .panel-body,
          + .panel-collapse > .list-group {
            border-color: $panel-inner-border;
            .lead {
              color: $text-color;
              font-size: $font-size-large;
            }
          }
        }

        .panel-title {
          font-size: ceil(($font-size-base * 1.428)); // 20px

          &::after {
            font-size: ceil(($font-size-base * 1.428)); // 20px
          }
        }

        .collapsed {
          border-color: transparent;
          background-color: $accordion-default-heading-bg;
        }

      }
    }
  }

  .panel-title{
    color: #00507f;
  }

}


