// Off canvas
//

//** Off-canvas z-index's
$zindex-offcanvas-background:     1000 !default;
$zindex-offcanvas-panel:          1010 !default;

//** Default width of parent off-canvas panel will occupy
$offcanvas-content-width:         85% !default;

//** Padding applied to the off canvas body
$offcanvas-header-padding:        15px !default;

//** Padding applied to the off canvas body
$offcanvas-body-padding:          0 !default;

//** Header border color
$offcanvas-header-border-color:   $gray-lighter !default;

//** Background color of off canvas content area
$offcanvas-content-bg:            white !default;

//** Backdrop background color
$offcanvas-backdrop-bg:           #000 !default;

//** Backdrop opacity
$offcanvas-backdrop-opacity:      .4 !default;