//// Block grid
////
//
////** Block title overlay
//$block-title-font-colour:               #fff !default;
//$block-title-font-size:                 ceil(($font-size-base * 1.2)) !default; // 26px 26/14
//$block-title-font-size-small:           $font-size-medium !default; // 16px
//
$block-title-overlay-bg:                #54585d;
$block-title-overlay-bg-hover:          $primary-base-color;
//$block-title-overlay-padding:           10px 15px !default;
//$block-title-overlay-padding-small:     5px 10px !default;
//$block-title-overlay-radius:            5px !default;
//$block-title-font-weight:               500 !default;
//
////** Block title overlay span
//$block-title-span-font-size:            ceil(($font-size-base * 1.571)) !default; // 22px 22/14
//$block-title-span-font-size-small:      $font-size-base !default; // 14px
