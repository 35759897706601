// A rare usage of the font-size mixing was necessary to achieve oversized phone, email etc
#header-information {
  display: none;

  > .container {
    display: -ms-flexbox;
    display: flex;
    // needed to avoid rebuild of whole area. Cms injects div with following class..
    .region-contact-info {
      align-self: center;
      -ms-flex-item-align: center;
      margin-left: auto;
      order:3;
    }
  }


  @include breakpoint($medium-up) {
    display: block;
  }

  // Brand logo
  .logo {
    @include make-md-column(3);
    padding: 0;
  }

  // Company strap-line
  .strapline {
    //@extend %header-info-bar;
    color: $header-info-strapline-color;
    font-size: $header-info-strapline-font-size-sm;
    text-align: left;
    margin: 0;

    @include make-md-column(6);

    padding-top: 22px;
    padding-left: 40px;

    @include breakpoint($large-up) {
      font-size: $header-info-strapline-font-size-lg;
      padding-top: 27px;
    }
  }

  .contact-info {
    //@include make-md-column(3);
    width:auto;
    text-align: right;

    .phone {
      line-height: 1;
      display: block;
      color: $header-info-phone-color;
      font-size: $header-info-phone-font-size-sm;

      @include breakpoint($large-up) {
        font-size: $header-info-phone-font-size-lg;
      }
    }

    .email {
      font-size: $header-info-email-font-size-sm;
      color: $header-info-email-color;

      @include breakpoint($large-up) {
        font-size: $header-info-email-font-size-lg;
        white-space: nowrap;
      }
    }

    .fa {
      color: $header-info-icon-color;
      font-size: $font-size-large;
      margin-right: 5px;
    }
  }
}

// shame, shame, shame
// ie8 doesn't support rem values
// stripping units doesn't produce similar results to rems so have to reset to lower values
.lt-ie9 #header-information {

  .strapline {
    //@include font-size(18);
  }

  .contact-info {

    .phone {
      //@include font-size(25);

      @include breakpoint($large-up) {
        //@include font-size(35);
      }
    }

    .email {
      //@include font-size(17);

      @include breakpoint($large-up) {
        //@include font-size(23);
      }
    }
  }
}
