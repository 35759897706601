//
// Gattaca Solutions page
//
//.page-careers {

.bg-instagram {
  background: $gray;
  .slick-dots {
    li, &:before, &:after {
      background: $gray;
    }
  }
  .slick-prev, .slick-next {
    &:before, &:after {
      color:#fff;
    }
  }
}

//}

