// Social bar
//

.social-bar {

  // Larger Social icons
  .list-share {
    font-size: $font-size-medium;
    margin: 0;
    text-align: center;

    @include breakpoint($small-up) {
      text-align: left;
    }

    li {
      padding-right: 5px;

      a {
        padding: 12px;
        width: 46px;
      }
    }
  }

  // Overrides default form input styles
  .form-control {
    border-color: transparent;
    box-shadow: none;
  }

  .col-2 {
    margin-top: 0;
  }
}