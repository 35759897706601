//// Masonry grids
////
//
//// Base styling and components
//.masonry-grid {
//
//  @extend %clearfix;
//
//  overflow: hidden;
//  position: relative;
//
//  list-style: none;     // Remove list item styling
//  margin-left: 0;
//  margin-bottom: 0;     // Remove default ul spacing
//
//  > li {
//    width: 100%;
//    display: block;
//    height: auto;
//    float: left;
//    padding: 0;
//    text-align: center;
//    position: relative;
//    overflow: hidden;
//    margin-top: $masonry-row-gap;
//
//    &:first-child {
//      margin-top: 0;   // Remove vertical gutter on first row
//    }
//  }
//}