// Primary navigation
//
//$navigation-primary-bg-small:                 #127eb9;
//$navigation-primary-bg-medium:                #127eb9;
//$navigation-primary-icon-color:               #fff;
//$navigation-primary-divider:                '|';
//$navigation-primary-divider-color:          $primary-dark-color;
$navigation-primary-color:                    #54585d;
//$navigation-primary-bg-active:                #1f4487;
//$navigation-primary-border-color:             transparent;
//$navigation-primary-font-size-small:        ceil(($font-size-base * 0.75));
//$navigation-primary-font-size-medium:       ceil(($font-size-base * 1));
//
//$navigation-user-divider-color:              $gray-lighter;
//$navigation-user-bg:                          #1f4487;
//$navigation-user-bg-active:                   #1f4487;

//$navigation-sub-border-color:                 #1f4487;

//
//
//// @extend %header-info-bar
////
//%header-info-bar {
//  color: #1d5a84;
//  padding: 15px 0;
//}
//
//// @extend %header-logo
////
//%header-logo {
//  width: 120px;
//  margin: 12px 10px 0 10px;
//}
