// Alerts
//

.alert {

  h4 {
    font-size: $font-size-h3;
  }

  p, ul {
    color: $gray-light;
    font-size: $font-size-medium;
  }
}






