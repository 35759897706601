// Buttons
//

//** Default button
$btn-default-color:             $gray-light !default; //#777
$btn-default-bg:                $gray-xlight !default; //#eee
$btn-default-hover-bg:          darken($btn-default-bg, 5%) !default;
$btn-default-icon-bg:           $btn-default-bg !default;

//** Primary button
$btn-primary-color:             #fff !default;
$btn-primary-bg:                $primary-base-color !default;
$btn-primary-hover-bg:          darken($btn-primary-bg, 5%) !default;
$btn-primary-icon-bg:           $btn-primary-bg !default;

//** Secondary button
$btn-secondary-color:           #fff !default;
$btn-secondary-bg:              $secondary-base-color !default;
$btn-secondary-hover-bg:        darken($btn-secondary-bg, 5%) !default;
$btn-secondary-icon-bg:         $btn-secondary-bg !default;
$btn-secondary-border:          transparent !default;

//** Tertiary button
$btn-tertiary-color:            #fff !default;
$btn-tertiary-bg:               lighten($gray-light, 10%) !default;
$btn-tertiary-hover-bg:         darken($btn-tertiary-bg, 5%) !default;
$btn-tertiary-icon-bg:          $btn-tertiary-bg !default;
$btn-tertiary-border:           transparent !default;

//** Cancel button
$btn-cancel-color:              $brand-primary !default;
$btn-cancel-bg:                 $gray-xlight !default; //#eee
$btn-cancel-hover-bg:           $gray-lighter !default;
$btn-cancel-border:             transparent !default;

//** Button sizes
$btn-base-font-size:            $font-size-medium !default; // 18px
$btn-lg-font-size:              $font-size-xlarge !default; // 24px
$btn-sm-font-size:              $font-size-base !default; // 14px
$btn-xs-font-size:              $font-size-small !default; // 12px

//** Button icon colours
$btn-icon-color:                #fff !default;
$btn-default-icon-color:        $btn-default-color !default;
$btn-primary-icon-color:        $btn-icon-color !default;
$btn-secondary-icon-color:      $btn-icon-color !default;
$btn-link-icon-color:           $secondary-base-color !default;