// Margin and Padding

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        1: (
                x: ($spacer-x * 1),
                y: ($spacer-x * 1)
        ),
        2: (
                x: ($spacer-x * 2),
                y: ($spacer-x * 2)
        ),
        3: (
                x: ($spacer-x * 3),
                y: ($spacer-x * 3)
        ),
        4: (
                x: ($spacer-x * 4),
                y: ($spacer-x * 4)
        ),
        5: (
                x: ($spacer-x * 5),
                y: ($spacer-x * 5)
        ),
        7: (
                x: ($spacer-x * 7),
                y: ($spacer-x * 7)
        ),
        10: (
                x: ($spacer-x * 10),
                y: ($spacer-x * 10)
        )
) !default;

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x: map-get($lengths, x);
    $length-y: map-get($lengths, y);

    // stop padding from happening on small screens
    .#{$abbrev}-a-#{$size} {
      #{$prop}: $length-y 0 !important;
      @include breakpoint($small-up) {
        #{$prop}: $length-y $length-x !important;
      }
    }
    // a = All sides
    .#{$abbrev}-t-#{$size} {
      #{$prop}-top: $length-y !important;
    }
    .#{$abbrev}-r-#{$size} {
      @include breakpoint($small-up) {
        #{$prop}-right: $length-x !important;
      }
    }
    .#{$abbrev}-b-#{$size} {
      #{$prop}-bottom: $length-y !important;
    }
    .#{$abbrev}-l-#{$size} {
      @include breakpoint($small-up) {
        #{$prop}-left: $length-x !important;
      }
    }

    // Axes
    .#{$abbrev}-x-#{$size} {
      @include breakpoint($small-up) {
        #{$prop}-right: $length-x !important;
        #{$prop}-left: $length-x !important;
      }
    }
    .#{$abbrev}-y-#{$size} {
      #{$prop}-top: $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}

// Positioning
.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-navbar-fixed;
}

// Centering
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

