// Email jobs panel
//
.panel-email-jobs {

  // Background gradient
  &.panel-bg {
    background-color: $email-jobs-bg;
    //@include linear-gradient(to bottom, $email-jobs-bg 50%, darken($email-jobs-bg, $email-jobs-darken));
  }

  // Panel body padding
  .panel-body {
    //padding: $panel-body-padding;
    padding: 20px 30px;

    //@include breakpoint($xsmall-up) {
    //  padding: $panel-body-padding*2;
    //}
  }

  label {
    color: white;
    font-size: $email-jobs-font-size-sm;
    @include breakpoint($xsmall-up) {
      font-size: $email-jobs-font-size-lg;
    }
    font-weight: normal;
    margin-bottom: 15px;
  }

  p {
    color: white;
    font-size: $email-jobs-font-size-sm/1.66; // 15px
    @include breakpoint($xsmall-up) {
      font-size: $email-jobs-font-size-lg/1.6; // 15px
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .input-group {

    // Sets width to input-group if nested in row-2-1 > col-1
    .row-2-1 .col-1 & {
      @include breakpoint($xsmall-up) {
        width: 100%;
      }
    }
  }

  .btn {
    background-color: $email-jobs-btn;
    color: white;
  }
}
