// Background title
//

.background-title {

  background-repeat: no-repeat;
  background-size: 1000px auto;
  background-position: center top;
  background-color: $background-title-bg;

  h1.title {
    padding: 50px 0 50px 0;
    color: #fff;
    text-align: center;
  }

  .row-1-1-1 {
    &:nth-of-type(2) {
      margin-bottom: 60px;
    }

    .col-wrap {
      //background-color: rgba(0, 0, 0, 0.7);
      border: 0;
      color: #fff;
      padding: 20px;
      font-weight: 200;
      display: block;
      text-decoration: none;
      h1, h2, h3, h4, span.heading {
        color: #fff;
        font-weight: 200;
        //text-transform: uppercase;
      }
      span.heading, span.desc {
        display: block;
        padding-bottom: 15px;
      }
      span.heading {
        padding-bottom: 5px;
        font-size: floor($font-size-h3);
      }
    }

    /* Anim effects */

    .effect-one {
      // hover effects aren't relevant on touch devices
      overflow: hidden;
      p {
        display: inline-block;
        //@include breakpoint($small-up) {
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: translate3d(0, 250px, 0);
        transform: translate3d(0, 250px, 0);
        //}
        &:first-child {
          -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
        }
        &:nth-of-type(2) {
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
        }
        &:nth-of-type(3) {
          -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
        }
      }
      &:hover p {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        &:first-child {
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
        }
        &:nth-of-type(2) {
          -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
        }
        &:nth-of-type(3) {
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
        }
      }
    }

    .effect-two {
      overflow: hidden;
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: #fff;
        content: '';
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
      }
      h3, p, span.heading, span.desc {
        position: relative;
        top: 0;
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
      }
      &:hover {
        h3, p, span.heading, span.desc {
          top: -5px;
          -webkit-transition: all 0.35s ease;
          transition: all 0.35s ease;
        }
        &::before {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
    }

    div:nth-of-type(odd) .col-wrap {
      background-color: rgba(darken($primary-base-color, 20%), 0.5);
    }
    div:nth-of-type(even) .col-wrap {
      background-color: rgba(#000000, 0.5);
    }
    div:nth-of-type(odd) {
      .bg-white {
        background-color: rgba(#ffffff, 0.8);
        color: $text-color;
        display: none;

        @include breakpoint($small-up) {
          display: block;
        }
      }
      .bg-transparent {
        background-color: transparent;
        padding:0;

      }
    }

  }

  @include breakpoint($xxsmall-only) {
    background-size: 1000px auto;
    background-position: center center;
  }

  @include breakpoint($xsmall-up) {
    background-size: 1000px auto;
  }

  @include breakpoint($small-up) {
    background-size: 1230px auto;
    h1.title {
      color: #fff;
      font-size: floor($font-size-h2);
      text-align: left;
      padding: 125px 0 125px 0;
    }
  }

  @include breakpoint($medium-up) {
    h1.title {
      padding: 125px 0 125px 0;
    }
  }

  @include breakpoint($large-up) {
    background-size: 1600px auto;
    h1.title {
      padding: 150px 0 150px 0;
      font-size: floor($font-size-h1);
    }
  }

}

.bg-pad{
    &:before {
      padding: 140px 0;
      @include breakpoint($small-up) {
      padding: 170px 0;
      }
      @include breakpoint($large-up) {
      padding: 220px 0;
      }
    }
}

.brand-strip {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 0;
  .row {
    margin-bottom: 0;

    // adjust column widths slightly to suit title column in first position
    .col-1 {
      color: #fff;
      text-transform: uppercase;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: floor($font-size-h3);
      text-align: center;
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column(2);
        text-align: left;
        font-size: floor($font-size-h6);
      }
    }

    .col-2, .col-3, .col-4, .col-5, .col-6, .col-7 {
      text-align: center;
      padding: 0 60px;
      img {
        display: inline-block;
        max-width: 200px;
        @include breakpoint($small-up, $no-query: true) {
          // re-instate the .img-responsive properties
          max-width: 100%;
        }
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column(1.65);
        padding: 0 15px;
      }
    }


  }
}


