// Donut Chart
// works alongside the donut-chart mixin
.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }
}

// To help cover-up the background-color bleed
.donut-chart-border {
  padding: 5px;
  overflow: hidden;

  border: 4px solid white; // Charts on white bg
  //border: 4px solid $section-intro-bg; // Charts on grey bg
  border-radius: 50%;

  width: 179px;
  height: 179px;

  position: absolute;
  top: 98px;
  left: -2px; // Charts on white bg
  //left: 19px; // Charts on grey bg
}

