// Promo panel
//
.panel-promo {
  border: 0;
  color: $panel-promo-text;
  font-size: $font-size-large;

  img {
    float: right;
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0));
  }

  a {
    color: $panel-promo-text;
  }
}