// Images
//

//** Apply this class to add spacing below hero image
.img-hero-spaced {
  margin-bottom: $img-hero-spacing;
}

// Rule to design the 'powered by google' logo placed at the top of the listings.
img.pg {
  padding-bottom: 20px;
  // dealing with smaller screens without media queries being necessary.
  width: 100%;
  max-width: 120px;

  @at-root .lt-ie9 & {
    width: auto;
  }
}

// Image width classes for use in CMS editor
//
.width-25,
.width-50,
.width-75,
.width-100 {
  width: 100%;
}

@include breakpoint($small-up) {

  // Image widths classes for desktop
  .width-25 { width: 25%; }
  .width-50 { width: 50%; }
  .width-75 { width: 75%; }
  .width-100 { width: 100%; }

  // Image alignment classes for desktop
  .content-left {
    float: left;
    margin-bottom: 40px;
    padding-right: 40px;

    &.clearboth {
      clear: both;
    }
  }

  .content-right {
    float: right;
    margin-bottom: 40px;
    padding-left: 40px;

    &.clearboth {
      clear: both;
    }
  }
}

// Hover effect can be applied as a roll-over effect
.hover-effect {
  &-default {
    &:hover {
      opacity: 0.70;
      filter: alpha(opacity=70);
    }
  }
}
