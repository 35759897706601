#password-policy-password-tab, #user-register-form {
  div.help-block.password-help {
    clear: left;
    display: block;
    padding-left: 15px;
  }

  div.progress {
    top: 10px;
    position: relative;
  }

  .has-feedback label ~ .form-control-feedback {
    right: 14px;
  }

  div.label.label-danger,
  div.label.label-warning,
  div.label.label-success {
    display: block;
    left: 105px;
    top: 3px;
    position: absolute;
  }

  div.form-type-password label.error {
    display: none;
  }
}

body.page-my-account-contractor-portal,
body.page-my-account-onboarding-portal {
  @include breakpoint($xsmall-only) {
    h1.rsm,
    h1.onboarding {
      display: inline-block;
      text-align: left;
      width: 100%;
    }

    div.rsm.button-wrapper,
    div.onboarding.button-wrapper {
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-bottom: 40px;

      a {
        display: inline-block;
        float: none !important;
      }
    }
  }

  @include breakpoint($xxsmall-only) {
    h1.rsm,
    h1.onboarding {
      display: inline-block;
      text-align: left;
      width: 100%;
    }

    div.rsm.button-wrapper,
    div.onboarding.button-wrapper {
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-bottom: 40px;

      a {
        display: inline-block;
        float: none !important;
      }
    }
  }

  @include breakpoint($small-up) {
    h1.rsm,
    h1.onboarding {
      display: inline-block;
    }

    div.rsm.button-wrapper,
    div.onboarding.button-wrapper {
      display: inline;
    }
  }

  .rsm-portal-wrapper > iframe,
  .onboarding-portal-wrapper > iframe {
    @include breakpoint($large-up) {
      height: 1750px !important;
    }
    @include breakpoint($xxsmall-only) {
      height: 1200px !important;
    }
    @include breakpoint($xsmall-only) {
      height: 1200px !important;
    }
    @include breakpoint($small-only) {
      height: 1200px !important;
    }
    @include breakpoint($medium-only) {
      height: 1650px !important;
    }
  }

  // Onboarding portal only
  //
  // overflow hidden enables iframe content to be hidden
  .onboarding-portal-wrapper {
    overflow: hidden;

    // negative top position hides the iframe content: user header bar
    > iframe {

      // the user header bar is deeper on viewports above 992px
      @include breakpoint($medium-up) {
        top: -56px;
      }

      position: relative;
      top: -50px;
    }
  }
}
