
@import "../public/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";

// Media sizes
//
// Common screen sizes | browser sizes:
// iPhone 4   - 320 x 480px | 480 x 212px
// iPhone 5   - 320 x 568px | 568 x 212px
// iPhone 6   - 375 x 667px | 667 x 267px
// iPhone 6+  - 414 x 736px | 736 x 306px
//

//** Here we define the lower and upper bounds for each media size
//$xsmall-max:      480px;    // 30em; - iPhone landscape
//$small-min:       481px;    // 30.0625em;
//$small-max:       768px;    // 48em; - iPad portrait
//$medium-min:      769px;    // 48.0625em;
//$medium-max:      1024px;   // 64em; - iPad landscape
//$large-min:       1025px;   // 64.0625em;
//$large-max:       1300px;   // 90em;

$xlarge-min:      1301px;   // 81.3125em;
$xlarge-max:      1500px;   // 93.75em;

//** Fixed widths
$medium-width:    745px;
$large-width:     1024px;
$xlarge-width:    1200px;

//$screen-xs:                  480px !default;
//$screen-xs-min:              $screen-xs !default;
//$screen-phone:               $screen-xs-min !default;
//
//$screen-sm:                  768px !default;
//$screen-sm-min:              $screen-sm !default;
//$screen-tablet:              $screen-sm-min !default;
//
//$screen-md:                  992px !default;
//$screen-md-min:              $screen-md !default;
//$screen-desktop:             $screen-md-min !default;
//
//$screen-lg:                  1200px !default;
//$screen-lg-min:              $screen-lg !default;
//$screen-lg-desktop:          $screen-lg-min !default;
//
//$screen-xs-max:              ($screen-sm-min - 1) !default;
//$screen-sm-max:              ($screen-md-min - 1) !default;
//$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              ($screen-lg-min - 1) !default;

//** Media Queries
$screen: "only screen";

// Added new breakpoint outside of Bootstrap to set a xxsmall boundary range from 0 - 480px screens
$xxsmall-up: $screen;
$xxsmall-only: ($screen) (max-width $screen-xs-min);

$xsmall-up: $screen (min-width $screen-xs-min); //480px
$xsmall-only: ($screen) (min-width $screen-xs-min) (max-width $screen-xs-max); //480px - 767px

$small-up: ($screen) (min-width $screen-sm-min); //768px
$small-only: ($screen) (min-width $screen-sm-min) (max-width $screen-sm-max); //768px - 991px

$medium-up: ($screen) (min-width $screen-md-min); //992px
$medium-only: ($screen) (min-width $screen-md-min) (max-width $screen-md-max); //992px - 1199px

$large-up: ($screen) (min-width $screen-lg-min); //1200px
$large-only: ($screen) (min-width $screen-lg-min) (max-width $screen-lg-max); //1200px - 1300px

$not-large: ($screen) (max-width $screen-md-max);

// Added new breakpoint outside of Bootstrap to set a xlarge boundary range from 1301+ screens
$xlarge-up: ($screen) (min-width $xlarge-min);
$xlarge-only: ($screen) (min-width $xlarge-min) (max-width $xlarge-max); //1301px +


//** Target retina devices
$img-retina: "#{$screen} and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)";


// Z index levels
//$z-index-low: 1;
//$z-index-mid: 10;
//$z-index-high: 20;
