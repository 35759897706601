// 4 columns
//
.row-1-1-1-1 {

  /*
  4 column row with equally split columns.
  For small up screens the columns become vertically stacked
  in the order they appear in the markup.
  -----------------------------------------
  |         |         |         |         |
  |  col-1  |  col-2  |  col-3  |  col-4  |
  |         |         |         |         |
  -----------------------------------------
  */

  // floating column to allow more to be added dynamically.
  > .col {
    padding-bottom:30px;
    margin-top:0;
    @include make-xs-column( 12 );
    @include breakpoint($small-up, $no-query: true) {
      &:nth-of-type(2n+1){
        clear:left;
      }
      @include make-sm-column( 6 );
    }
    @include breakpoint($medium-up, $no-query: true) {
      padding-bottom:0;
      &:nth-of-type(2n+1){
        clear:none;
      }
      &:nth-of-type(4n+1){
        clear:left;
      }
      @include make-md-column( 3 );
    }
  }

  > .col-1 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      @include make-md-column( 3 );
    }
  }

  > .col-2 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      @include make-md-column( 3 );
    }
  }

  > .col-3 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      @include make-md-column( 3 );
    }
  }

  > .col-4 {
    @include make-xs-column( 12 );
    @include breakpoint($medium-up, $no-query: true) {
      @include make-md-column( 3 );
    }
  }

  // Adds vertical spacing when rows stack
  @extend %row-stack-spacing-medium-up;
}
