//// Singularity 1.2+ Syntax
//@include add-grid(12);
//@include add-gutter(0.5);
//@include sgs-change('output', 'isolation'); // Default in Singularity
//
//html {
//  @include background-grid;
//}
//
//* {
//  @include box-sizing('border-box');
//}
//
///* Make HTML5 elements display block-level for consistent styling */
//.header,
//.main,
//.footer {
//  display: block;
//}
