.form-control {
  border-radius: 0;
}

input.form-control, .form-select {
  //border: 1px solid #dbdbdb;
  //min-width: 90%;
  //max-width: 100%;
}

// Select box with its down arrow replaced.
.select-style {

  // Reset select input styling
  select {
    box-shadow: none;
    background: white;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    border-width: 1px;
    border-style: solid;
  }

  select::-ms-expand {
    display: none;
  }

  select:focus {
    outline: none;
  }

  // Force select wraps to use maximum available width
  &.form-select-max, .form-select {
    width: 100%;
  }

  // Disabled select text and icon colour
  select[disabled],
  select[disabled] ~ span {
    color: $input-color-placeholder;
  }
}

// Input icon
.input-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: $input-height-base;
  height: $input-height-base;
  line-height: $input-height-base;
  text-align: center;
  pointer-events: none;

  // Small input icon
  &.input-icon-sm {
    width: $input-height-small;
    height: $input-height-small;
    line-height: $input-height-small;
  }

  // Large input icon
  &.input-icon-lg {
    width: $input-height-large;
    height: $input-height-large;
    line-height: $input-height-large;
  }
}

// Form control with icon
.has-icon {
  display: block;
  position: relative;
  width: auto;
  vertical-align: middle;

  // Ensure icons don't overlap text
  .form-control {
    padding-right: ($input-height-base * 1);
  }

  &.select-style, .form-select {
    .form-control {
      // Ensure select can cover icon so clicking icon
      // triggers opening the select
      padding-right: 0;
    }

    // Make selects with icons full width to cover icon
    select {
      width: 100%;
    }
  }
}

// Input group addon adjustments for large inputs
// Allows an addon btn with icon to be positioned vertically centered
.input-group-lg {

  .input-group-btn {

    .btn {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// Shame list...
.lt-ie10 {

  // Hide icons on selects as the down arrow on selects
  // cannot be removed in CSS (easily if at all)
  .has-icon {
    &.select-style {
      .input-icon {
        display: none;
      }

      .form-control {
        padding-right: $input-height-base / 2;
      }
    }
  }
}