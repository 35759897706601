.page-test {
  // Add fake content structure the same as layout pages
  .console-col-wrap {
    padding: 20px;
    background-color: $gray-xlight;
    border: 1px dashed $gray;
  }
  // Add gap between layouts on test page
  .row-1-2,.row-1-1-1-1,.row-1-1-1-1-1-1{
    padding-top:$grid-gutter-width/2;
  }
}