@import "../public/bower_components/breakpoint-sass/stylesheets/breakpoint";

// Add styling to captcha
@import "vendor/captcha";

// Load fonts that might be needed by all other sass
@import "vendor/font-awesome";

// overwrite dp nasties
@import "vendor/dp";

// overwrite share this nasties
@import "vendor/share-this";

// Load Bootstrap 3 to base the site on
//@import "vendor/bootstrap/variables";
@import "gattaca/vendor/bootstrap/variables";

@import "vendor/bootstrap/bootstrap";

// Load critical variables first, followed by all the others
@import "variables/core/_font-geometria.scss";
@import "variables/core/_font-lato.scss";
@import "variables/core/_font-montserrat.scss";
@import "variables/core/_font-roboto.scss";
@import "variables/core/_grid.scss";
@import "variables/core/_layout.scss";
@import "variables/core/_typography.scss";
@import "gattaca/variables/core/_colours.scss";
@import "gattaca/variables/core/_typography.scss";

@import "variables/_accordion.scss";
@import "variables/_back-to-top.scss";
@import "variables/_background-title-hero.scss";
@import "variables/_background-title.scss";
@import "variables/_blockquote.scss";
@import "variables/_breadcrumb.scss";
@import "variables/_button.scss";
@import "variables/_carousel.scss";
@import "variables/_charting.scss";
@import "variables/_dropdown.scss";
@import "variables/_email-jobs.scss";
@import "variables/_featured-projects.scss";
@import "variables/_floats.scss";
@import "variables/_footer-navigation.scss";
@import "variables/_form-filter-checkbox.scss";
@import "variables/_form-filter-input.scss";
@import "variables/_form-filter-radio.scss";
@import "variables/_header-information.scss";
@import "variables/_images.scss";
@import "variables/_listing.scss";
@import "variables/_masonry.scss";
@import "variables/_nav.scss";
@import "variables/_navigation-primary.scss";
@import "variables/_off-canvas.scss";
@import "variables/_panels.scss";
@import "variables/_search-form.scss";
@import "variables/_search-home.scss";
@import "variables/_search-job-sectors.scss";
@import "variables/_social-icons.scss";
@import "variables/_spacing.scss";
@import "variables/_tables.scss";
@import "variables/_vow.scss";
@import "gattaca/variables/_email-jobs.scss";
@import "gattaca/variables/_featured-projects.scss";
@import "gattaca/variables/_footer-navigation.scss";
@import "gattaca/variables/_header-information.scss";
@import "gattaca/variables/_listing.scss";
@import "gattaca/variables/_masonry.scss";
@import "gattaca/variables/_navigation-primary.scss";
@import "gattaca/variables/_off-canvas.scss";
@import "gattaca/variables/_search-form.scss";
@import "gattaca/variables/_search-home.scss";
@import "gattaca/variables/_vow.scss";

@import "mixins/_accordion.scss";
@import "mixins/_block-grid.scss";
@import "mixins/_buttons.scss";
@import "mixins/_charting.scss";
@import "mixins/_forms.scss";
@import "mixins/_image.scss";
@import "mixins/_masonry.scss";
@import "mixins/_media-listing.scss";
@import "mixins/_mixins.scss";
@import "mixins/_panels.scss";
@import "mixins/_primary_nav.scss";
@import "mixins/_site-header.scss";
@import "mixins/_transitions.scss";
@import "base/_grid.scss";
@import "base/_images.scss";
@import "base/_media.scss";
@import "base/_tables.scss";
@import "base/_typography.scss";
@import "base/forms/_errors.scss";
@import "base/forms/_forms.scss";
@import "base/layout/_2-column-left.scss";
@import "base/layout/_2-column-right.scss";
@import "base/layout/_2-column.scss";
@import "base/layout/_3-column.scss";
@import "base/layout/_3-to-2-column-stacked.scss";
@import "base/layout/_4-column.scss";
@import "base/layout/_6-column.scss";
@import "base/layout/_7-column.scss";
@import "base/layout/_block-grid.scss";
@import "base/layout/_layout.scss";
@import "base/layout/_single-column.scss";
@import "components/_accordion.scss";
@import "components/_alerts.scss";
@import "components/_article-heading.scss";
@import "components/_article-listing.scss";
@import "components/_back-to-top.scss";
@import "components/_background-title-hero.scss";
@import "components/_background-title.scss";
@import "components/_breadcrumb.scss";
@import "components/_browse-featured.scss";
@import "components/_browse-filter.scss";
@import "components/_buttons.scss";
@import "components/_email-jobs.scss";
@import "components/_featured-projects.scss";
@import "components/_featured-recruiter-title.scss";
@import "components/_filter-listing.scss";
@import "components/_filter-selected.scss";
@import "components/_footer-navigation.scss";
@import "components/_footer.scss";
@import "components/_form-favourites-jobs.scss";
@import "components/_form-filter-checkbox.scss";
@import "components/_form-filter-input.scss";
@import "components/_form-filter-radio.scss";
@import "components/_gray-carousel.scss";
@import "components/_header-information.scss";
@import "components/_job-actions.scss";
@import "components/_job-options.scss";
@import "components/_listing-heading.scss";
@import "components/_listing.scss";
@import "components/_masonry.scss";
@import "components/_navigation-alternative.scss";
@import "components/_navigation-client.scss";
@import "components/_navigation-primary.scss";
@import "components/_off-canvas.scss";
@import "components/_previous-next.scss";
@import "components/_progress-bars.scss";
@import "components/_search-form.scss";
@import "components/_search-job-sectors.scss";
@import "components/_share-bar.scss";
@import "components/_social-bar.scss";
@import "components/_social-icons.scss";
@import "components/_static_slider.scss";
@import "components/_utils-icons.scss";
@import "components/_wells.scss";
@import "components/panels/_about.scss";
@import "components/panels/_accordion.scss";
@import "components/panels/_job-manager.scss";
@import "components/panels/_listing.scss";
@import "components/panels/_panel.scss";
@import "components/panels/_promo-hover.scss";
@import "components/panels/_promo.scss";
@import "gattaca/components/_navigation-primary.scss";

@import "hybrid-components/_masonry.scss";
@import "hybrid-components/_search-home.scss";
@import "pages/_gattaca-careers.scss";
@import "pages/_gattaca-solutions.scss";
@import "pages/_home.scss";
@import "pages/_page-my-account-dashboard.scss";
@import "pages/_page-overwrite.scss";
@import "pages/_test.scss";
@import "pages/_vow.scss";
@import "helpers/_accessibility.scss";
@import "helpers/_animations.scss";
@import "helpers/_backgrounds.scss";
@import "helpers/_bootstrap.scss";
@import "helpers/_borders.scss";
@import "helpers/_charting.scss";
@import "helpers/_images.scss";
@import "helpers/_responsive-embed.scss";
@import "helpers/_screen-reader.scss";
@import "helpers/_sizing.scss";
@import "helpers/_spacing.scss";
@import "helpers/_status.scss";
@import "helpers/_typography.scss";
@import "helpers/_visiblility.scss";

@import "vendor/cookie";

// Slick Carousel
@import "../public/bower_components/slick-carousel/slick/slick.scss";
@import "vendor/slick-carousel/_mt-theme.scss";

/*
* Be careful when overwriting, libsass and/or globbing produce
* unpredictable results if commented imports are included.
*/
