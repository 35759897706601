// Layout structure
//

// Header
//
header {
  position:relative; // to hide overflow of position absoluted header image on homepage only
  //overflow:hidden; // to hide overflow of position absoluted header image on homepage only
}

// Footer
//
footer {}

// Row
//
.row {
  @extend %clearfix;
  //clear: both;
}

// Provides default vertical spacing between columns when stacking
// occurs below the large breakpoint
// To use add to a row class using,
//    @extend %row-stack-spacing
//
%row-stack-spacing-large-up {
  div:first-child {
    margin-top: 0;
  }

  > div {
    margin-top: $layout-stacked-gutter;
  }

  @include breakpoint($large-up, $no-query: true) {
    > div {
      margin-top: 0;
    }
  }
}

// Same as above but use when stacking occurs below the medium breakpoint
%row-stack-spacing-medium-up {
  div:first-child {
    margin-top: 0;
  }

  > div {
    margin-top: $layout-stacked-gutter;
  }

  @include breakpoint($medium-up, $no-query: true) {
    > div {
      margin-top: 0;
    }
  }
}

// Same as above but use when stacking occurs below the small breakpoint
%row-stack-spacing-small-up {
  div:first-child {
    margin-top: 0;
  }

  > div {
    margin-top: $layout-stacked-gutter;
  }

  @include breakpoint($small-up, $no-query: true) {
    > div {
      margin-top: 0;
    }
  }
}

// Same as above but use when stacking occurs below the xsmall breakpoint
%row-stack-spacing-xsmall-up {
  div:first-child {
    margin-top: 0;
  }

  > div {
    margin-top: $layout-stacked-gutter;
  }

  @include breakpoint($xsmall-up, $no-query: true) {
    > div {
      margin-top: 0;
    }
  }
}

// Main container
//
.main {
  section {
    @extend %clearfix;
  }

  padding: $layout-main-padding;
}

.row-contain{
  margin-left:0!important;
  margin-right:0!important;
}

// Container to restrict width of row
//
//.inner {
//  margin: 0 auto;
//  position: relative;
//
//  // We deliberately add
//  padding: 0 10px;
//
//  @extend %clearfix;
//
//  @include breakpoint($large-up) {
//    width: $large-width;
//    padding: 0 15px;
//  }
//
//  @include breakpoint($xlarge-up, $no-query: true) {
//    width: $xlarge-width;
//    padding: 0 15px;
//  }
//}