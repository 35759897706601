.bs-docs-side-bar {
  &.affix {
    position: fixed;
    top: 20px;
  }
  display: inline-block !important;
  position:relative;
  top:70px;
  .nav > li > a:hover, .nav > li > a:focus {
    background: none;
  }
  .dropdown-menu{
    position:static;
    float:none;
    border:none;
    box-shadow:none;
    margin-bottom:15px;
    margin-left:15px;
    border-top:1px solid $secondary-base-color;
    border-radius:0;
    > li > a{
      padding:5px 0;
      &:hover{
        background:none;
      }
    }
  }
}

.bs-example{
  padding:10px 0 10px 0;
}

.bs-example-control-sizing input[type="text"] + input[type="text"], .bs-example-control-sizing select {
  margin-top: 10px;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eee;
  background-color: rgba(86, 61, 124, .15);
  border: 1px solid #ddd;
  border: 1px solid rgba(86, 61, 124, .2);
}

.affix-nudge {
  position: fixed;
  bottom: 20px;
}