// Job actions
//

.job-actions {

  .col-2 {
    margin-top: 0;

    .list-utils {
      padding-top: 5px;
      text-align: right;

      @include breakpoint($medium-up) {
        text-align: right;
      }
    }

    .list-share {
      padding-top: 20px;
    }
  }
}