// Listing panel
//
.panel-listing {
  background: $panel-listing-bg-main;
  margin-bottom: 0;
  border: 0;
  @include box-shadow(0 0 0 rgba(0, 0, 0, 0));

  // Panel heading
  .panel-heading {
    background: $panel-listing-bg-head;
    @extend %heading-margin-reset;
  }

  // Panel Body content
  .panel-body {
    border-bottom: 1px solid #fff;

    .title {
      display: block;
      font-size: $font-size-large;
      margin-bottom: 5px;
    }

    // Text highlight
    .highlight {
      color: $panel-text-highlight;
    }
  }

  // Panel list group
  .list-group {
    font-size: $font-size-medium;

    .list-group-item {
      padding: 0;
      background-color:transparent;
      border-color:#fff;
      a {
        display: block;
        padding: 10px 15px;
      }
    }
  }

  .btn {
    .btn-icon {
      border-radius: 0; // Overrides default button border radius
    }
  }
}
