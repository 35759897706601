// Responsive: Utility classes
//

.show-inline-touch,
.show-block-touch {
  display: none;
}

div.url-textfield {
  display: none;
}

.hide-inline-touch {
  display: inline-block;
}
.hide-block-touch {
  display: block;
}

.touch {
  .show-inline-touch {
    display: inline-block;
  }
  .show-block-touch {
    display: block;
  }
  .hide-touch {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.hide {
  //  position: absolute;
  //  visibility: hidden;
  //  display: block;

  position: absolute !important;
  left: -999em !important;
}

// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: http://getbootstrap.com/getting-started/#support-ie10-width
// Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@-ms-viewport {
  width: device-width;
}

// Visibility utilities
.visible-xxsmall-block,
.visible-xxsmall-inline,
.visible-xxsmall-inline-block,
.visible-xsmall-block,
.visible-xsmall-block-up,
.visible-xsmall-inline,
.visible-xsmall-inline-block,
.visible-small-block,
.visible-small-inline,
.visible-small-inline-block,
.visible-medium-block,
.visible-medium-inline,
.visible-medium-inline-block,
.visible-large-block,
.visible-large-inline,
.visible-large-inline-block,
.visible-xlarge-block,
.visible-xlarge-inline,
.visible-xlarge-inline-block {
  display: none !important;
}

.visible-xxsmall-block {
  @include breakpoint($xxsmall-only) {
    display: block !important;
  }
}
.visible-xsmall-block {
  @include breakpoint($xsmall-only) {
    display: block !important;
  }
}
.visible-xsmall-block-up {
  @include breakpoint($xsmall-up) {
    display: block !important;
  }
}
.visible-xsmall-inline {
  @include breakpoint($xsmall-only) {
    display: inline !important;
  }
}
.visible-xsmall-inline-block {
  @include breakpoint($xsmall-only) {
    display: inline-block !important;
  }
}

.visible-small-block {
  @include breakpoint($small-only) {
    display: block !important;
  }
}
.visible-small-inline {
  @include breakpoint($small-only) {
    display: inline !important;
  }
}
.visible-small-inline-block {
  @include breakpoint($small-only) {
    display: inline-block !important;
  }
}

.visible-medium-block {
  @include breakpoint($medium-only) {
    display: block !important;
  }
}
.visible-medium-inline {
  @include breakpoint($medium-only) {
    display: inline !important;
  }
}
.visible-medium-inline-block {
  @include breakpoint($medium-only) {
    display: inline-block !important;
  }
}

.visible-large-block {
  @include breakpoint($large-only, $no-query: true) {
    display: block !important;
  }
}
.visible-large-inline {
  @include breakpoint($large-only, $no-query: true) {
    display: inline !important;
  }
}
.visible-large-inline-block {
  @include breakpoint($large-only, $no-query: true) {
    display: inline-block !important;
  }
}

.visible-xlarge-block {
  @include breakpoint($xlarge-only) {
    display: block !important;
  }
}
.visible-xlarge-inline {
  @include breakpoint($xlarge-only) {
    display: inline !important;
  }
}
.visible-xlarge-inline-block {
  @include breakpoint($xlarge-only) {
    display: inline-block !important;
  }
}

@include breakpoint($xsmall-only) {
  @include responsive-invisibility('.hidden-xsmall');
}

@include breakpoint($small-only) {
  @include responsive-invisibility('.hidden-small');
}

@include breakpoint($medium-only) {
  @include responsive-invisibility('.hidden-medium');
}

@include breakpoint($large-only) {
  @include responsive-invisibility('.hidden-large');
}

@include breakpoint($xlarge-up, $no-query: true) {
  @include responsive-invisibility('.hidden-xlarge');
}