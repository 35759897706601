// About panel
//
.panel-about {
  background: $panel-about-bg;
  @extend %heading-margin-reset;
  border: 0;

  .img-profile {
    border-bottom: 5px solid $secondary-base-color;
  }
}
