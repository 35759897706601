// Panels
//

$panel-text-highlight:            $gray-dark;
$panel-promo-text:                #fff;

//** Listing panel
$panel-listing-bg-main:           $gray-xlight;
$panel-listing-bg-head:           $gray-lighter;

//** Listing with button panel
$panel-listing-butt-font-size:    85%;

//** Accordion panel
$panel-accordion-bg:              #e5eff4;
$panel-accordion-bg-head:         #c8dde7;

//** Job Manager panel
$panel-job-manager-bg:            $primary-lighter-color;
$panel-job-manager-color:         $brand-primary;
$panel-job-manager-border:        #fff;
$panel-job-manager-foot-color:    $primary-dark-color;

//** About panel
$panel-about-bg:                  $primary-lighter-color;

%heading-margin-reset {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4 {
    margin: 0;
  }
}
