// Form filter checkbox
//

.filter-radio {
  background: $form-filter-radio-bg;
  display: inline-block;
  padding: 0 10px;
  border: 0;
  color: $form-filter-radio-color;
  min-width: 100%;
  margin:0 0 15px 0;

  .radio {
    margin: 0;
    padding: 5px 0;

    input {
      margin-top: 2px;
    }
  }
}
