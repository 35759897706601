//
// Voice of the Workforce page
//

// The container class for all VoW CMS pages
.vow-content {

  // Hero image container
  .hero-container {
    position: relative;
  }

  // Lead paragraph
  .lead {
    text-align: center;
    width: 90%;
  }

  // Section headings
  .section-heading {
    background-color: $vow-section-heading-bg;
    text-align: left;
    margin-bottom: 0;

    h2 {
      color: $vow-section-heading-color;
      font-size: (($font-size-base * 1.5)); // 22px 22/14
      font-weight: $vow-section-heading-font-weight;
      margin: 0;
      padding: 20px 0;

      // Allows wrapping of long headings
      span {
        display: block;

        @include breakpoint($medium-up, $no-query: true) {
          display: inline;
        }
      }

      // Heading background images on medium-up viewports
      @include breakpoint($medium-up, $no-query: true) {
        font-size: 30px;
        line-height: 100px;
        padding: 0;

        &.heading-bg-people {
          background: url(#{$get-path-to-assets}/header-people.png) right bottom no-repeat;
        }

        &.heading-bg-hands {
          background: url(#{$get-path-to-assets}/header-hands.png) right bottom no-repeat;
        }
      }
    }
  }

  // Section content
  .section-content {

    .h3 {
      font-size: (($font-size-base * 1.42)); // 20px 20/14
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      margin-bottom: 0;
      padding: 20px 0;

      @include breakpoint($medium-up, $no-query: true) {
        font-size: (($font-size-base * 2.42)); // 34px 34/14
        padding: 70px 0;

        &.heading-bg-globe {
          background: url(#{$get-path-to-assets}/header-globe.png) left bottom no-repeat;
          padding: 60px 0 40px 200px;
        }
      }

      span {
        display: block;

        &.heading-style-1 {
          color: $vow-heading-color-primary;
        }
        &.heading-style-2 {
          color: $vow-heading-color-default;
        }
        &.heading-style-3 {
          color: $vow-heading-color-secondary;
        }
      }
    }

    &.bg-filled {
      background-color: $vow-section-intro-bg;
    }
  }

  // Accordion
  .section-accordion {
    padding-left: 15px;
    padding-right: 15px;

    @include breakpoint($medium-up, $no-query: true) {
      .col-1 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .panel-group {
      margin-bottom: 0;

      .panel {
        font-size: (($font-size-base * 2)); // 28px 28/14

        .panel-heading {
          background-color: $vow-section-accordion-bg;
          border-top: none;
          color: white;
          padding: 20px 0;
          text-decoration: none;

          .panel-title {
            position: relative;

            span {
              display: inline-block;
              width: 85%;

              @include breakpoint($medium-up, $no-query: true) {
                display: inline;
                width: 100%;
              }
            }

            // Accordion chevron background
            &:after {
              background-color: $vow-section-intro-bg;
              border-radius: 50%;
              width: 45px;
              height: 45px;
              padding-top: 9px;
              text-align: center;

              // Chevron position
              position: absolute;
              top: -10px;
              right: 0;
            }
          }
        }
      }
    }
  }
}

// Jump Menu
//
.vow-jump-menu {
  margin-top: 30px;
  margin-bottom: 30px;

  // Centers label and select
  @include breakpoint($small-up, $no-query: true) {
    text-align: center;
  }

  .pane-title {
    color: $text-color;
    font-size: 22px;
  }

  .pane-content {

    @include breakpoint($small-up, $no-query: true) {
      display: inline-block;
    }

    form {
      width: 100%;

      @include breakpoint($small-up, $no-query: true) {
        width: auto;
        min-width: 340px;
      }

      select {
        color: $brand-primary;
        font-size: 22px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
      }
    }
  }
}

// Donut charts
//

// Chart & text side-by-side
//.chart-group-2 {
//  width: 990px;
//
//  .chart-container {
//    display: table;
//    width: 100%;
//    padding-top: 100px;
//    padding-bottom: 100px;
//    position: relative;
//  }
//
//  .donut-chart {
//    float: left;
//  }
//
//  .donut-chart-text {
//    color: $vow-section-intro-bg;
//    font-size: 26px;
//    padding-left: 20px;
//    display: table-cell;
//    vertical-align: middle;
//  }
//}

// Chart & text stacked
//.chart-group-4 {
//  width: 990px;
//
//  .chart-container {
//    padding-top: 100px;
//    padding-bottom: 100px;
//    position: relative;
//  }
//
//  .donut-chart {
//    margin: 0 auto 20px auto;
//  }
//
//  .donut-chart-text {
//    color: white;
//    display: block;
//    font-size: 14px;
//    font-weight: $font-weight-regular;
//    text-transform: uppercase;
//    text-align: center;
//  }
//}

// Matchtech theme charts
//

// Charts on white background
//
// Engineering
//@include donut-chart('.vow-light-49', 49);
//@include donut-chart('.vow-light-13', 13);

// Aerospace
//@include donut-chart('.vow-light-42', 42);
//@include donut-chart('.vow-light-9', 9);

// Automotive
//@include donut-chart('.vow-light-50', 50);
//@include donut-chart('.vow-light-15', 15);

// Property / Construction
//@include donut-chart('.vow-light-50', 50);
//@include donut-chart('.vow-light-16', 16);

// Highways
//@include donut-chart('.vow-light-46', 46);
//@include donut-chart('.vow-light-16', 16);

// Rail
//@include donut-chart('.vow-light-55', 55);
//@include donut-chart('.vow-light-8', 8);

// Water and Environment
//@include donut-chart('.vow-light-42', 42);
//@include donut-chart('.vow-light-13', 13);

// Industrial
//@include donut-chart('.vow-light-47', 47);
//@include donut-chart('.vow-light-6', 6);

// Maritime
//@include donut-chart('.vow-light-56', 56);
//@include donut-chart('.vow-light-18', 18);

// Engergy
//@include donut-chart('.vow-light-52', 52);
//@include donut-chart('.vow-light-17', 17);

// Engineering Tech
//@include donut-chart('.vow-light-43', 43);
//@include donut-chart('.vow-light-18', 18);

// Charts on grey background
//
// Engineering
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-25', 25, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-34', 34, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-29', 29, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-24', 24, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);

// Aerospace
//@include donut-chart('.vow-dark-32', 32, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-15', 15, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-33', 33, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-28', 28, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-25', 25, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);

// Automotive
//@include donut-chart('.vow-dark-19', 19, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-19', 19, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-33', 33, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-35', 35, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-19', 19, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);

// Property / Construction
//@include donut-chart('.vow-dark-33', 33, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-24', 24, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-9', 9, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-31', 31, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-33', 33, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-24', 24, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);

// Highways
//@include donut-chart('.vow-dark-29', 29, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-18', 18, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-15', 15, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-10', 10, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-37', 37, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-25', 25, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);

// Rail
//@include donut-chart('.vow-dark-31', 31, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-20', 20, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-18', 18, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-10', 10, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-42', 42, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-31', 31, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-19', 19, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-9', 9, $center: $vow-section-intro-bg);

// Water and Environment
//@include donut-chart('.vow-dark-30', 30, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-34', 34, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-31', 31, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-24', 24, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-10', 10, $center: $vow-section-intro-bg);

// Industrial
//@include donut-chart('.vow-dark-34', 34, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-22', 22, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-18', 18, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-8', 8, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-22', 22, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-31', 31, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-20', 20, $center: $vow-section-intro-bg);

// Maritime
//@include donut-chart('.vow-dark-26', 26, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-26', 26, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-10', 10, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-37', 37, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-30', 30, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-22', 22, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);

// Engergy
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-26', 26, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-35', 35, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-23', 23, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-27', 27, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-15', 15, $center: $vow-section-intro-bg);

// Engineering Tech
//@include donut-chart('.vow-dark-32', 32, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-15', 15, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-10', 10, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-24', 24, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-37', 37, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-23', 23, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-17', 17, $center: $vow-section-intro-bg);


// Networkers theme charts
//

// Overall technology
//
// Charts on white background
//@include donut-chart('.vow-light-54', 54);
//@include donut-chart('.vow-light-16', 16);

// Charts on grey background
//@include donut-chart('.vow-dark-25', 25, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-17', 17, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-12', 12, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-34', 34, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-36', 36, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-19', 19, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);

// IT
//
// Charts on white background
//@include donut-chart('.vow-light-52', 52);
//@include donut-chart('.vow-light-11', 11);

// Charts on grey background
//@include donut-chart('.vow-dark-23', 23, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-15', 15, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-14', 14, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-32', 32, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-34', 34, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-23', 23, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-11', 11, $center: $vow-section-intro-bg);

// Telecommunications
//
// Charts on white background
//@include donut-chart('.vow-light-57', 57);
//@include donut-chart('.vow-light-27', 27);

// Charts on grey background
//@include donut-chart('.vow-dark-26', 26, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-18', 18, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-16', 16, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-13', 13, $center: $vow-section-intro-bg);
//
//@include donut-chart('.vow-dark-36', 36, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-40', 40, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-16', 16, $center: $vow-section-intro-bg);
//@include donut-chart('.vow-dark-7', 7, $center: $vow-section-intro-bg);

