// Job options
//

.job-options {

  // override the base layout settings for this area only
  @include breakpoint($medium-only) {
    .col-1, .col-2 {
      width: 50%;
    }
  }

  .table-primary {
    margin-bottom: 0;
  }

  // Save, Email & SMS services
  .list-utils {
    //font-size: $font-size-small;
    text-align: right;
    margin-top:10px;

    li {
      a {
        color: $gray-light;

        &:hover {
          color: darken($brand-primary, 10%);
          text-decoration: none;
        }

        i {
          margin-right: 5px;
        }

        .add-to-shortlist {
          &.selected {
            i {
              color: $icon-shortlist-selected-color;
            }
          }
        }

        &.add-to-shortlist {
          &.selected {
            i {
              color: $icon-shortlist-selected-color;
            }
          }
        }

      }
    }
  }

  // Social icons position
  .list-share {
    @include breakpoint($medium-up) {
      text-align: right;
    }
  }

}