#sliding-popup {
  border-top:1px dotted #fff;
  &.sliding-popup-bottom {
    background: $primary-dark-color;
  }
  .popup-content {
    #popup-text {
      max-width:70%;
      padding-top:10px;
      p {
        font-weight: 300;
        font-size: $font-size-base;
      }
    }
    #popup-buttons {
      max-width:30%;
      button {
        margin-top: 7px;
        margin-bottom:7px;
        @extend .btn, .btn-sm, .btn-secondary;
        box-shadow:none;
        text-shadow:none;
        font-family:$font-family-sans-serif;
        &:hover{
          background:$btn-secondary-hover-bg;
        }
      }
    }
  }
}