.row-1-1-1-1-1-1-1 {

  // Custom layout based on 7 column grid
  // 6 Stacked rows for extra small screens up.
  // 3 columns stacked above 3 then 1 full width for medium screens up.
  //  -------------------
  //  |                 |
  //  |       c-7       |
  //  |                 |
  //  -------------------
  //  |     |     |     |
  //  | c-1 | c-2 | c-3 |
  //  |     |     |     |
  //  -------------------
  //  |     |     |     |
  //  | c-4 | c-5 | c-6 |
  //  |     |     |     |
  //  -------------------
  //
  //  7 equal columns for large screens and up.
  //  -------------------------------------------
  //  |     |     |     |     |     |     |     |
  //  | c-1 | c-2 | c-3 | c-4 | c-5 | c-6 | c-7 |
  //  |     |     |     |     |     |     |     |
  //  -------------------------------------------

  //@include layout($gutter: 0.2) {
    .col-1 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($medium-up) {
        @include make-md-column( 12 );
        padding-top: 0;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
      }
    }

    .col-2 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;
      }
    }

    .col-3 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;

      }
    }

    .col-4 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;
        clear: none;
      }
    }

    .col-5 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;
      }
    }

    .col-6 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;
      }
    }

    .col-7 {
      padding-top: 10px;
      margin:0;

      @include make-xs-column( 12 );
      @include breakpoint($small-up) {
        @include make-sm-column( 4 );
        padding-top: 10px;
      }
      @include breakpoint($large-up, $no-query: true) {
        @include make-lg-column( 1.71 );
        padding-top:0;
      }
    }
  //}

}

