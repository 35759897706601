// 3 columns
//
.row-1-1-1 {
  /*
  3 column row with equally split columns.
  For small screens the columns become vertically stacked
  in the order they appear in the markup.
  -------------------------------
  |         |         |         |
  |  col-1  |  col-2  |  col-3  |
  |         |         |         |
  -------------------------------
  */
  > .col-1 {
    @include make-xs-column( 12 );
    @include breakpoint($small-up, $no-query: true) {
      //@include grid-span(4, 1);
      @include make-xs-column( 4 );
    }
  }

  > .col-2 {
    @include make-xs-column( 12 );
    @include breakpoint($small-up, $no-query: true) {
      //@include grid-span(4, 5);
      @include make-xs-column( 4 );
    }
  }

  > .col-3 {
    @include make-xs-column( 12 );
    @include breakpoint($small-up, $no-query: true) {
      //@include grid-span(4, 9);
      @include make-xs-column( 4 );
    }
  }

  @extend %row-stack-spacing-small-up;
}

// 3 columns on medium up screen size
//
.row-1-1-1-medium-up {
  /*
  3 column row with equally split columns.
  For medium up screens the columns become vertically stacked
  in the order they appear in the markup.
  -------------------------------
  |         |         |         |
  |  col-1  |  col-2  |  col-3  |
  |         |         |         |
  -------------------------------
  */
  > .col-1 {
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 1);
      @include make-md-column( 4 );
    }
  }

  > .col-2 {
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 5);
      @include make-md-column( 4 );
    }
  }

  > .col-3 {
    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 9);
      @include make-md-column( 4 );
    }
  }

  @extend %row-stack-spacing-medium-up;
}