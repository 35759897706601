// Listing
//

.job-listings {

  .listing {

    // A promoted listing will inherit a light-blue bg to highlight from a standard listing
    &.promoted {

      .listing-header {
        background-color: $promo-listing-header-bg;
      }

      .listing-content {
        background-color: $promo-listing-content-bg;
      }
    }

    // Listing header contains the H3, labels and add-to-shortlist icon
    .listing-header {
      background-color: $listing-header-bg;
      position: relative;
      padding-right: 55px; // Stops the title being overlapped by the add-to-shortlist star icon
      overflow: hidden;

      h3 {
        margin: 0;
        padding: 12px 15px;

        a {
          display: block;
          margin-right: 10px;
          font-size:$font-size-listing-heading;
          color:$promo-listing-header-color;

          @include breakpoint($xsmall-up) {
            display: inline;
          }
        }

        .label {
          font-size: $promo-listing-label; // 16px 16/14
          font-weight: normal;
          padding: $listing-header-label-padding;
          position:relative;
          top:$promo-listing-label-top;

          + .label {
            margin-left: 6px; // Includes the 4px inline-block space. Margin-left = 10px
          }
        }

        .label-promoted {
          background-color: $label-promoted-bg;
        }
        .label-justadded {
          background-color: $label-justadded-bg;
        }
        .label-featured {
          background-color: $label-featured-bg;
        }
      }

      // Add to shortlist icon
      .add-to-shortlist {
        background-color: $add-to-shortlist-bg;
        color: $add-to-shortlist-color;
        padding: $add-to-shortlist-padding;
        position: absolute;
        top: 0;
        right: 0;
        @include breakpoint($large-up) {
          bottom: 0;
        }

        i {
          color: $icon-shortlist-color;
          font-size: ceil(($font-size-base * 1.571)); // 22px 22/14
        }

        // The .selected class applies a fill colour to the icon after being clicked
        &.selected {
          i {
            color: $icon-shortlist-selected-color;
          }
        }

      }
    }

    .listing-content {
      font-size: ceil(($font-size-base * 1.142)); // 16px 16/14
      position: relative;
      padding: 15px;

      ul {
        color: $primary-dark-color;
        margin-bottom: 0;

        li {

          // List icons
          &:before {
            font-family: 'FontAwesome';
            font-size: ceil(($font-size-base * 1.428)); // 20px 20/14
            width: (18em / 14);
            color: $gray-mlight;
            display: inline-block;
          }

          // Custom Font Awesome classes
          &.fa-ref:before {
            content: $fa-var-pencil;
          }

          // generic rate
          &.fa-rate:before {
            content: $fa-var-money;
          }

          &.fa-rate-gbp:before {
            content: $fa-var-gbp;
          }

          &.fa-rate-usd:before {
            content: $fa-var-usd;
          }

          &.fa-rate-eur:before {
            content: $fa-var-eur;
          }

          &.fa-location:before {
            content: $fa-var-map-marker;
          }
        }
      }

      .company-logo {
        padding-top:20px;
        @include breakpoint($medium-up) {
          float:right;
        }
      }

    }
  }
}


