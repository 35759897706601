// Borders
//

// Default border for vertically dividing sections
// These can be used for any container or element

.border-top {
  border-top: 1px solid $secondary-base-color;
}
.border-bottom {
  border-bottom: 1px solid $secondary-base-color;
}

//** Gray
.border-top-gray {
  border-top: 1px solid $gray-xlight;
}
.border-bottom-gray {
  border-bottom: 1px solid $gray-xlight;
}

//** Orange thick border
.border-top-orange {
  border-top: 3px solid $secondary-dark-color;
}
.border-bottom-orange {
  border-bottom: 3px solid $secondary-dark-color;
}
.border-thick-bottom-orange {
  border-bottom: 5px solid $secondary-dark-color;
}

//** Applies border on all sides
.border-all {
  border: 1px solid $secondary-base-color;
}

// Border colour palettes TODO: To be extended to control border palette
.border-light-gray {
  border-color: $gray-xlight;
}
.btn-border-right {
  border-right: 2px solid #fff;
}