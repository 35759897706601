// Backgrounds
//

//** Solid colour based
.bg-default {
  background-color: $gray-xlight;
}

.bg-headers {
  background-color: $primary-dark-color;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-secondary {
  background-color: $primary-light-color;
}

.bg-tertiary {
  background-color: $primary-lighter-color;
}

.bg-quaternary {
  background-color: #fff;
}

.bg-medium-gray {
  background-color: $gray-mlight;
}

.bg-light-gray {
  background-color: $gray-lighter;
}

.bg-search {
  background: $primary-dark-color url("../images/backgrounds/#{$search-bg-img}") no-repeat 0 0;
  background-size: cover;
}

.bg-promo {
  background: $primary-dark-color;
}

//** Shame
//.lt-ie9 .bg-search {
//  background: $primary-dark-color;
//}

//.lt-ie9 .bg-promo {
//  background: $primary-dark-color;
//}