// 3 columns (2 cols over 1 col)
//
.row-2-over-1 {
  /*
  For small screens:
  Behaves like a standard 3 column row (row-1-1-1)
  -------------------------------
  |         |         |         |
  |  col-1  |  col-2  |  col-3  |
  |         |         |         |
  -------------------------------

  For large screens and up:
  Creates a row for columns 1 and 3 (row-1-1) and stacks
  these above column 2 in it's own row.
  -------------------------------
  |              |              |
  |    col-1     |    col-3     |
  |              |              |
  -------------------------------
  |                             |
  |            col-2            |
  |                             |
  -------------------------------
  */
  > .col-1 {
    @include breakpoint($small-up) {
      //@include grid-span(6, 1);
    }

    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 1);
    }
  }

  > .col-2 {
    // Give space between rows when col 1 & 3 stacked above col 2
    margin-top: $layout-stacked-gutter;

    @include breakpoint($small-up) {
      //@include grid-span(12, 1);
      clear: both;
    }

    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 5);
      margin-top: 0;
    }
  }

  > .col-3 {
    margin-top: $layout-stacked-gutter;

    @include breakpoint($small-up) {
      //@include grid-span(6, 7);
      margin-top: 0;
    }

    @include breakpoint($medium-up, $no-query: true) {
      //@include grid-span(4, 9);
      margin-top: 0;
    }
  }
}