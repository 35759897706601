// Filter selected
//

.active-filters {
  margin-top: 10px;
  margin-bottom: 0;
  margin-left:0;

  li {
    background-color: $gray-lighter;
    border-radius: 5px;
    color: $primary-base-color;
    margin-bottom: 10px;
    margin-right: 10px;
    float: left;
    clear: left;

    // Aligns search filters inline from xsmall-up
    @include breakpoint($xsmall-up) {
      clear: none;
    }

    span {
      display: table-cell;
      vertical-align: middle;
      padding: 5px;
    }

    // Non table cell
    //a {
    //  text-decoration: none;
    //  margin-left: 5px;
    //  padding-left: 5px;
    //  padding-right: 5px;
    //}

    a {
      display: table-cell;
      text-decoration: none;
      text-align: center;
      vertical-align: middle;
      padding: 5px;
      color:inherit;
    }

    &.filters-label {
      background: transparent;
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;

      span {
        padding-left: 0;
        padding-right: 0;
      }
    }

    // The clear all link has a different bg colour to the selected filters
    &.clear-all {
      background-color: white;
    }
  }
}
