@charset "UTF-8";

// Default Variables

$slick-font-path: "../fonts/" !default;
$slick-font-family: $icon-font-family !default;
$slick-loader-path: "../images" !default;
$slick-arrow-color: $gray-dark !default;
$slick-dot-color: black !default;
$slick-dot-color-hover: $secondary-dark-color !default;
$slick-dot-color-active: $slick-dot-color-hover !default;
$slick-prev-character: $fa-var-angle-left !default;
$slick-next-character: $fa-var-angle-right !default;
$slick-dot-character: $fa-var-circle !default;
$slick-dot-size: 18 !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-arrow-size: 55;
$slick-arrow-offset: 5px;
$slick-arrow-offset-small: 50px;

$slick-gutter-size: 10px;

@function slick-image-url($url) {
  //  @if function-exists(image-url) {
  //      @return image-url($url);
  //  }
  //  @else {
  @return url($slick-loader-path + $url);
  //  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.carousel {
  padding-bottom: 30px;
  .carousel-inner {
    padding: 0 30px;
    .slick-prev {
      left: $slick-arrow-offset;
    }
    .slick-next {
      right: $slick-arrow-offset;
    }
    .slick-dots {
      left: 0;
    }
  }
}

.carousel-small {
  .carousel-inner {
    padding: 0;
  }
}

.carousel-small-arrows {
  .carousel-inner {
    padding: 0 30px;
  }
}

.carousel-medium {
  .carousel-inner {
    padding: 0 25px;
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-dots {
      left: 0;
    }
  }
}

.carousel-instagram {
  .slick-slider {
    .slick-list {
      .item {
        padding: 0 15px;
        img {
          width: 100%;
          height: auto;
          //max-height:90px;
        }
      }
    }
  }
}

.carousel-oneslide {
  .slick-slider {
    .slick-list {
      .item {
        img {
          //max-height:90px;
        }
      }
    }
  }
}

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("/ajax-loader.gif") center center no-repeat;
  }
}

/* Gutters */

.slick-with-gutters {
  .slick-slide {
    padding-left: $slick-gutter-size/2;
    padding-right: $slick-gutter-size/2;
  }
}

/* *MT - specific slide item styling */
.slick-slider {
  &.with-border {
    .slick-list {
      border-right: 1px solid $gray-xlight;
      .item-plain {
        border-left: 1px solid $gray-xlight;
      }
    }
  }
  .slick-list {
    .item-plain {
      text-align: center;
      display: block;
      justify-content: center;
      align-content: center;
      flex-direction: column; /* column | row */
      padding: 0 10px;
      img {
        display: inline-block;
        //max-height:90px;
      }
      p {
        font-size: $font-size-p-carousel;
      }
    }
  }
}


/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: #{$slick-arrow-size}px;
  width: auto; //#{$slick-arrow-size}px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -#{$slick-arrow-size / 2}px;
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $slick-font-family;
  @include font-size($slick-arrow-size);
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -$slick-arrow-offset-small;
  @include breakpoint($small-up) {
    left: -$slick-arrow-offset;
  }
  [dir="rtl"] & {
    left: auto;
    right: -$slick-arrow-offset-small;
    @include breakpoint($small-up) {
      right: -$slick-arrow-offset;
    }
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -$slick-arrow-offset-small;
  @include breakpoint($small-up) {
    right: -$slick-arrow-offset;
  }
  [dir="rtl"] & {
    left: -$slick-arrow-offset-small;
    @include breakpoint($small-up) {
      left: -$slick-arrow-offset;
    }
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

// Inline prev & next buttons
.slick-inline-prev,
.slick-inline-next {
  position: relative;
  left: 0;
  right: 0;
  width: #{$slick-arrow-size}px;
}

/* Dots */

//.slick-slider {
//    margin-bottom: 30px;
//}

// Custom class that should be added to the slick container if
// dots are enabled.
// As dots are positioned absolutely a bottom margin is required.
// Without this class the slider remains neutral about bottom space.
.slick-has-dots {
  margin-bottom: 40px;
}

.slick-dots {
  // Positioned absolutely,so that they do not contribute towards
  // the height of the slider and therefore influence the vertical
  // centering of the prev/next buttons!
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  padding: 1px 0 0 0;
  width: 100%;

  // Reset for standard ULs
  margin-left: 0;
  margin-bottom: 0;
  bottom: -45px;
  height: 45px;

  &:before, &:after {
    content: " ";
    height: 30px;
    width: 15px;
    display: inline-block;
    background: #fff;
    position:relative;
    top:10px;
  }

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: #fff;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        @include font-size($slick-dot-size);
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }

  }
}

.slick-slider {
  // Removes :focus state
  *:focus {
    outline: none;
  }
}

.slick-slide img.slick-loading {
  display: none;
}

.js {
  // stop carousel content from showing in a way that doesn't mask the height of each slide.
  // one element of rectifying the FOUC issues.
  .carousel-inner {
    overflow: hidden;
    height: 0;
  }
}

