// Previous and Next nav
//

.previous-next {

  .btn {
    font-size: $font-size-large;

    .btn-icon {
      color: $secondary-base-color;
    }

    &.btn-prev {
      .btn-icon {
        background-color: transparent;
        margin-right: 5px;
      }
    }
    &.btn-next {
      .btn-icon {
        background-color: transparent;
        margin-left: 5px;
      }
    }
  }
}