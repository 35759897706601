// Primary nav
//
#nav-primary {

  // We need to display whole menu as off canvas relies on it, so we hid 3rd & 2nd levels by CSS for
  // large up display where non off canvas menu is used.
  @include breakpoint($medium-up) {
    #block-matchtech-site-matchtech-main-menu .dropdown-submenu:hover > .dropdown-menu {
      display: none;
    }

    #block-matchtech-site-matchtech-main-menu .caret {
      display: none;
    }
  }

}
